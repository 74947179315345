import React from 'react'
import './style.css'
import BannerSection from './BannerSection/BannerSection'
import AboutSection from './AboutSection/AboutSection'
import FundingImpactSection from './FundingImpactSection/FundingImpactSection'
import MoreInfoSection from './MoreInfoSection/MoreInfoSection'
import PartnerUkraineSetion from './PartnerUkraineSetion/PartnerUkraineSetion'


const Home = () => { 
  return (
    <div>
      <BannerSection />
      <AboutSection />
      <FundingImpactSection />
      <MoreInfoSection />
      <PartnerUkraineSetion />
    </div>
  )
}

export default Home