import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {  RouterProvider, createBrowserRouter } from "react-router-dom";
import "./global.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./components/Home/Home";
import Summit2024 from "./components/Summit24/Summit2024";
import About from "./components/About/About";
import Summit23 from "./components/Summit2023/Summit23";
import Signup from "./components/Signup/Signup";
import ThankYou from "./components/RegisteredSuccesful/ThankYou";
import Registration from "./components/Registration/Registration";
import Membership from "./components/Membership/Membership";
import FundingOverview from "./components/GrantsOverview/Grants";
const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    children:[
      {
        path: "/",
        element: <Home/>
      },
      {
        path: "/about",
        element: <About/>
      },
      {
        path: "/summit24",
        element: <Summit2024/>
      },
      {
        path: "/summit23",
        element: <Summit23/>
      },
      {
        path: "/ENFA-AI/sign-up",
        element: <Signup/>
      },
      {
        path: "/ENFA-AI/thank-you",
        element: <ThankYou/>
      },
      {
        path: "/ENFA-AI/registration",
        element: <Registration/>
      },
      {
        path: "/membership",
        element: <Membership/>
      },
      {
        path: "/funding-overview",
        element: <FundingOverview/>
      }
    ]
  },
  
]);
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <RouterProvider router={router} >
  <App />
</RouterProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
