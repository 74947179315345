import React from 'react'
import member_1 from './../../../assets/images/about/Niloufar-Gharavi.jpg'
import member_2 from './../../../assets/images/about/member_2.jpg'
import member_3 from './../../../assets/images/about/member_3.jpg'
import member_4 from './../../../assets/images/about/member_4.jpg'
import member_5 from './../../../assets/images/about/member_5.jpg'
import member_6 from './../../../assets/images/about/member_6.jpg'
import member_7 from './../../../assets/images/about/member_7.jpg'
import member_8 from './../../../assets/images/about/member_8.jpg'
import member_9 from './../../../assets/images/about/member_9.jpg'
import member_10 from './../../../assets/images/about/member_10.jpg'
import member_11 from './../../../assets/images/about/member_11.jpg'
import member_12 from './../../../assets/images/about/member_12.jpg'
import member_13 from './../../../assets/images/about/member_13.jpg'
import member_14 from './../../../assets/images/about/member_14.jpg'
import member_15 from './../../../assets/images/about/member_15.jpg'
import nicola_vita from './../../../assets/images/about/Nicola-Vita.jpg'
import './Teams.css';
const SingleMemberCardModel = (props) => {
    return (
        <div className="card modelCard card_header_dialog container-fluid">
            {
                props.sendId == 1 &&
                <div className="row">
                    <div className="col-2 modelImgCnt">
                        <img loading='lazy' src={member_1} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col modelCntPara">
                        <div className="card-body">
                            <h1 className="d-block memberHeading">Niloufar Gharavi</h1>
                            <h5 className="d-block subTeamName">Founder & Secretary General</h5>
                            <p className="memberParaDesc">
                                Is one of the leading entrepreneurial and systemic designers globally. She has a BA in Architecture from University of Isfahan, Iran, and the Architectural Association in London (AA), MA in Design from Oslo School of Architecture and Design (AHO) and the Royal Danish Academy (KADK).
                            </p>
                            <p className="memberParaDesc">
                                She is founder of the world’s first design-driven venture studio, having built successful companies and projects under its portfolio - varied from tech startups to humanitarian large scale projects together with organizations such as NRC, IOM, UNDP, UDI, Red Cross, etc.
                            </p>
                            <p className="memberParaDesc">
                                She founded Euro Nordic Funding Alliance to make public funding more accessible to a lot of serial entrepreneurs like herself.
                            </p>
                            <p className="memberParaDesc">
                                She is author of methodologies such as :
                                Design-Driven Entrepreneurship
                                @Home In Transition (empowering asylum seekers and refugees to become employable as well as self-employed)
                                Oslo Welcome Center - Cooperative for migrants
                            </p>
                        </div>
                    </div>
                </div>
            }


            {
                props.sendId == 2 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={member_6} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            <h1 className="d-block memberHeading">Tom O. Kleppesto</h1>
                            <h5 className="d-block subTeamName">Chairman, ENFA Norway</h5>
                            <p className="memberParaDesc">
                            (MSc) is the General Manager for the Maritime Oslofjord Alliance, comprising the three maritime and ocean industry associations Oslo Shipowners’ Association, Ocean Industry Forum Oslofjord, and Shipping & Offshore Network.
                            </p>
                            <p className="memberParaDesc">
                            Shipping & Offshore Network was one of 19 partners in the Go LNG project, with Klaipėda Science and Technology Park as lead partner (2016–19), together with 20 partners in the Blue Supply Chain project (2022-2025).
                            </p>
                            <p className="memberParaDesc">
                            His professional background includes executive education, talent management, entrepreneurship, innovation, and media.
                            </p>
                            <p className="memberParaDesc">
                            Tom is Chairman and Co-founder of the Lithuanian-Norwegian Chamber of Commerce. He has been involved with the Rafto Foundation for Human Rights.
                            </p>
                        </div>
                    </div>
                </div>
            }


            {
                props.sendId == 3 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={member_3} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            <h1 className="d-block memberHeading">Carlos Franco Uliaque</h1>
                            <h5 className="d-block subTeamName">Global Board Of Directors, ENFA Spain</h5>
                            <p className="memberParaDesc">
                                Senior EU Project Manager and President of Association of Developia Foundation. He has a degree in Economics University of Zaragoza, has a master’s in leadership from USJ and of International Trade from Paris XIII University in addition to a Maîtrise in Economic Sciences at Aix-Marseille University.
                            </p>
                            <p className="memberParaDesc">
                                Carlos has over 20 years of experience as European Project manager in both the private and the public sectors. He directly led over 200 projects in such different fields: training, business innovation, economic development, entrepreneurship, local and regional politics, energy efficiency, renewable energy, sustainable development social affairs, gender equality in the workplace, youth, human resources management, equal opportunities, etc. and applying for all kinds of European programs: H2020, Interreg, Erasmus, Leonardo, Progress, EuropeAid, etc.
                            </p>
                            <p className="memberParaDesc">
                                He is a member of the council of the University of Zaragoza, on the board of the Association for Self-employed Workers in Aragon (ATA), Spain and member of the council of protection of the environment in Aragon, Member of the Internationalisation Council of CEOE Aragón (Employers' Association).
                            </p>

                        </div>
                    </div>
                </div>
            }


            {
                props.sendId == 4 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={nicola_vita} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                        <h1 className="d-block memberHeading">Nicola Vita</h1>
                        <h5 className="d-block subTeamName">Global Board Of Directors, ENFA Italy</h5>
                            <p className="memberParaDesc">
                            Policy development specialist with over 10 years’ experience in the area of the Cohesion Policy.
                            </p>
                            <p className="memberParaDesc">
                            In 2017 he was appointed as manager of the Italian branch of the European Leader Association for Rural Development.
                            </p>
                            <p className="memberParaDesc">
                            In 2022 he was designated as main representative of the Italian Industry Partnership in the Monitoring Committee of the Interreg Greece - Italy Programme 2021/2027.
                            </p>
                            <p className="memberParaDesc">
                            In 2023 he was elected as Vice-President at CONFIMI Industria Basilicata, one of the major Italian industry SME network with more than 40,000 associated firms countrywide.
                            </p>
                        </div>
                    </div>
                </div>
            }


            {
                props.sendId == 5 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src="/static/media/comminsonnNew.446384447eace037bb00.png" className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">

                           <h1 className="d-block memberHeading">Anna M. Gentile</h1>
                           <h5 className="d-block subTeamName">ENFA Italy</h5>
                            <p className="memberParaDesc">
                            Graduated in economics; she holds a MA in Territorial Planning. She has been working for about twenty years at the top of national-level business firms in the field of consulting services to Public Administrations and companies.
                            </p>
                            <p className="memberParaDesc">
                            Over time she acquired strong skills, especially in the domain of entrepreneurship and self-employment. She has also contributed to the implementation of several important transnational Projects.
                            </p>
                            <p className="memberParaDesc">
                            Moreover, in 2015/2016, she coordinated the teams in charge of the development of the ESF ROPs 2014/2020 on behalf of Basilicata Region and Abruzzo Region.
                            </p>
                            <p className="memberParaDesc">
                            In 2020 she was appointed as Steering Committee member at the Energy Cluster of Basilicata Region.
                            </p>
                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 6 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={member_4} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">

                        <h1 className="d-block memberHeading">Alessandro Carbone</h1>
                        <h5 className="d-block subTeamName">Global Board Of Directors, ENFA Cyprus</h5>
                            <p className="memberParaDesc">
                            Master in Social Work and Divinity, he is trainer and EU Project Manager since 1996 first as junior, later on as writer and implementer of funded programs.
                            </p>
                            <p className="memberParaDesc">
                            His expertise and experience enables him to write and coordinate multicultural partnership, consult organisations and supervise professionals working on project with public fundings.
                            </p>
                            <p className="memberParaDesc">
                            He matches the above with competences related to the fields of social innovation and entrepreneurship.
                            </p>
                            <p className="memberParaDesc">
                            He is the director of T-HAP LTD, consulting company in Cyprus
                            </p>    
                        </div>
                    </div>
                </div>
            }

            {
                props.sendId == 12 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={member_15} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                        <h1 className="d-block memberHeading">Maria Tintul</h1>
                        <h5 className="d-block subTeamName">Ukraine Projects Advisor</h5>
                            <p className="memberParaDesc">
                                Having 9 years of experience in IT Business Development Mariia has had a chance to work in various industries: IT outsourcing, Product Development, Social and Cultural Heritage projects supported by governmental Fund, working on social projects with UNICEF, curating Service business in creating business XR applications of any sort (including the work with Telenor Norway) and now finally creating a Ukrainian scale up startups support program by being in investment and startup growth.
                                This Program just won the Accelerator of the Year award by the Ukrainian Startup Fund, the biggest governmental organisation working with startups in the country.
                            </p>
                            <p className="memberParaDesc">
                                Mariia can combine startup thinking and corporate experience in order to try to validate the relevance and impact of new ideas and concepts emerging on the market. The Sales, PR and Partnerships background allows her to always see the roadmap, even when we only just discuss the ideas.
                            </p>
                        </div>
                    </div>
                </div>
            }

{
                props.sendId == 13 &&
                <div className="row">
                    <div className="col-md-4">
                        <img loading='lazy' src={member_14} className="modelContentImg img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                        <h1 className="d-block memberHeading">Villeman Vinje</h1>
                        <h5 className="d-block subTeamName">Strategic Advisor</h5>
                            <p className="memberParaDesc">
                            Economist with 20 years of broad and varied experiences in this domain. Educated as an Economist from University of Edinburgh, a MSc in Business Strategy from BI Norwegian Business School and an Authorized Financial Analyst at NHH, he initially started out with Business Strategy within Management Consulting.
                            </p>
                            <p className="memberParaDesc">
                            His experience as a political advisor at the Finance Committee at the Parliament, and as an Economist at Civita, a liberal, market oriented Think Tank, shifted his focus to complex macroeconomic topics, such as tax reforms, policy for innovation and value creation, reform of the public sector and incentives and policies for transforming the Economy to increase sustainability and circularity.
                            </p>
                            <p className="memberParaDesc">
                            The last six years he has worked with these topics as a Partner at NyAnalyse, an Economic Consultancy while also investing in and advising start-ups.
                            </p>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default SingleMemberCardModel