import React from 'react'
import './BannerSection.css'
import bannerVideo from '../../../assets/images/homeBanner/ENFAHome.mp4'
import ReactPlayer from 'react-player'
const BannerSection = () => {
  return (
    <section className="bannerSectionBlue">
      <ReactPlayer
        id="bgVideo"
        url={bannerVideo}
        playing
        playsinline
        muted
        loop
        width="100%"
        height="100%"
        config={{
          file: {
            attributes: {
              loading: 'lazy'
            }
          }
        }}
      />
      <div className="bannerSectionBlueBG">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div
                className="d-flex justify-content-center align-items-center h-100 flex-column bannerContent"
              >
                <span className="d-block text-uppercase textCourageous"
                >Funding Impact</span>
                <h1>Euro Nordic Funding Alliance</h1>

                <p className='bannerSectionP'>Nordic & Pan-European Grants | Cross-Border Consortium Building | Proposal Development
                  | AI, Tech & Innovation | Knowledge Sharing & Capacity Building | Project & Fund Management</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BannerSection