import React from 'react'
import Frame1 from  '../../../assets/images/homeBanner/Frame7.png'
import Frame2 from  '../../../assets/images/homeBanner/Frame7-1.png'
import Frame3 from  '../../../assets/images/homeBanner/Frame7-2.png'
import Frame4 from  '../../../assets/images/homeBanner/Frame7-3.png'
import Frame5 from  '../../../assets/images/homeBanner/Frame7-4.png'
import Frame6 from  '../../../assets/images/homeBanner/Frame7-5.png'
import Frame7 from  '../../../assets/images/homeBanner/Frame7-6.png'
import Frame8 from  '../../../assets/images/homeBanner/Frame7-7.png'
import Frame9 from  '../../../assets/images/homeBanner/Frame7-8.png'
import Frame10 from  '../../../assets/images/homeBanner/Frame7-9.png'
import Frame11 from  '../../../assets/images/homeBanner/Frame7-10.png'
import Frame12 from  '../../../assets/images/homeBanner/Frame7-11.png'

import './MoreInfoSection.css'

const infoData = [
    {
      imgSrc: Frame1,
      amount: 'EURO 116 MILLION',
      description: 'LIFE PROGRAM',
    },
    {
      imgSrc: Frame2,
      amount: 'EURO 2.8 BILLION',
      description: 'NORWAY & EEA GRANTS',
    },
    {
      imgSrc: Frame3,
      amount: 'EURO 372 MILLION',
      description: '​ INVEST EU',
    },
    {
      imgSrc: Frame4,
      amount: 'EURO 26.2 BILLION',
      description: 'ERASMUS+ 2021- 2027',
    }, {
      imgSrc: Frame5,
      amount: 'EURO 806.9 BILLION 2027',
      description: 'NEXTGENERATION EU',
    },
    {
      imgSrc: Frame6,
      amount: 'EURO 1.13 BILLION',
      description: 'EIC Accelerator 2023',
    },
    {
      imgSrc: Frame7,
      amount: 'EURO 1.6 BILLION',
      description: 'EIC 2023 Breakthrough Innovation',
    },
    {
      imgSrc: Frame8,
      amount: 'EURO 4.3 BILLION ',
      description: 'Norway, Worlds 10th largest ODA donor',
    },
    {
      imgSrc: Frame9,
      amount: 'EURO 37.8 BILLION ',
      description: 'REBUILDING UKRAINE',
    },
    {
      imgSrc: Frame10,
      amount: 'EURO 700 BILLION INVESTMENT ',
      description: 'UKRAINE RECOVERY PLAN',
    },
    {
      imgSrc: Frame11,
      amount: 'EURO 18 BILLION',
      description: 'EU 2023 SUPPORT PACKAGE FOR UKRAINE',
    },
    {
      imgSrc: Frame12,
      amount: 'EURO 23 BILLION ',
      description: 'WORLD BANK TRUST FUND',
    },
  ];
  const MoreInfoCard = ({ imgSrc, amount, description }) => (
    <div className="col-md-3">
      <div className="moreInfoCard">
        <div className="overflow-hidden img">
          <img loading='lazy' src={imgSrc} alt="" />
        </div>
        <h4 className="d-block text-uppercase">{amount}</h4>
        <span className="d-block text-uppercase">{description}</span>
      </div>
    </div>
  );
const MoreInfoSection = () => {
  return (
    <section className="homeMoreInfoSection">
    <div className="container">
      <div className="mx-auto billionBlock text-center">
        <span className="d-block"
        >There is more than €1000 billion available in grant funding from EU
          Commission, regional and national grant programs in the next
          decade.</span>
        <p>
          In Europe, grant funding is dispersed across a myriad of programs,
          initiatives, and funding channels, encompassing the European Union,
          national governments, regional authorities, and private foundations.
          </p>
          <p>
          The ENFA network will help you navigate in this vast array of
          opportunities and enable you to qualify for the grant appropriate
          for your projects and make a positive impact in the social and
          environemental domain.
        </p>
      </div>
      <div className="container">
        <div className="row">
          {infoData.map((info, index) => (
            <MoreInfoCard key={index} {...info} />
          ))}
        </div>
      </div>
    </div>
  </section>
  )
}

export default MoreInfoSection