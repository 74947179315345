import React from 'react'
import './ENFAService.css'
import img from "../../../assets/images/Membership/ENFAFrame1.png"
import Frame1 from "../../../assets/images/Membership/Frame_1.JPEG"
import Frame2 from "../../../assets/images/Membership/Frame_2.JPEG"
import Frame3 from "../../../assets/images/Membership/Frame_3.JPEG"
import Frame4 from "../../../assets/images/Membership/Frame_4.JPEG"
import Frame5 from "../../../assets/images/Membership/Frame_5.JPEG"
import Frame6 from "../../../assets/images/Membership/Frame_6.JPEG"

const serviceListData = [{
    img: Frame1,
    title: 'Access to Exclusive Funding Opportunities',
    description: 'Analysis and Monitoring of Programs and Calls for Proposals. Early notifications of funding programs and grants relevant to your organization’s mission.'
}, 
{
    img: Frame2,
    title: 'Networking & Collaboration',
    description: 'Connect with a diverse network of stakeholders, share knowledge, and build Partnerships for mutual benefit. Promote Inclusion in cross-border consortia and match-making'
},
{
    img: Frame3,
    title: 'Proposal Development A-Z',
    description: 'From idea or ongoing business to project proposals, match-making for the relevant calls, managing the proposal process and applications to alleviate the administrative burdens.'
},
{
    img: Frame4,
    title: 'Capacity Building & Peer Learning',
    description: 'Access to training and workshops on topics such as project management, proposal writing, and sustainability practices. Enhance your expertise to increase your success.'
},
{
    img: Frame5,
    title: 'Visibility & Recognition',
    description: 'Promote your organization’s achievements and projects through our network. Gain recognition and visibility by being featured in our publications, events, and digital platforms.'
},
{
    img: Frame6,
    title: 'Access to Exclusive Resources & Discounts',
    description: 'Analysis and Monitoring of Programmes and Calls for Proposals. Early notifications of funding programs and grants relevant to your organization’s mission.'
}]


const ServiceDetails = ({ img, title, description }) => (
<div className="col-xl-4 col-lg-6 col-xs-6 col-md-6 col-sm-6 col-12 my-4 px-3">
        <div className="position-relative servicecard">
            <div className="overflow-hidden position-absolute serviceCircle">
                <img loading='lazy' src={img} />
            </div>
            <div className="position-relative text-center serviceContent">
                <h3 > {title.split(' ').map((word, index) => (
                    <span key={index}>
                        {index === 3 ? <br /> : null}
                        {word}{' '}
                    </span>
                ))}</h3>
                <p>{description}</p>
            </div>
        </div>
    </div>
);
const ENFAService = () => {
    return (
        <section className="enfa-service">
            <div className="container">
                <div className="h-100 container mx-auto">
                    <div className="d-flex align-items-center justify-content-center flex-column h-100 mb-4">
                        <h3 className='Services'>
                            Services
                        </h3>
                        <h2 className=" text-center mx-auto ENFAServiceHeading my-3">
                        SERVICES IN NUTSHELL
                        </h2>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        {serviceListData?.map((info, index) => (
                            <ServiceDetails key={index} {...info} />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ENFAService