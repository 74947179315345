import React from 'react'
import emp_big from '../../../assets/images/emp-big.png'
import emp_small from '../../../assets/images/emp-small.png'
import './AboutSection.css'
import emp_mobile from '../../../assets/images/HomeEmpowerMob.png'

const AboutSection = () => {
  return (
    <section className="homeAboutSection">
      <div className="homeAboutSectionBG">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
              <div className="homeEnfaBox">

                <div className="titleEmpowering">
                  Empowering Growth
                </div>
                <div className="text-uppercase textEnfa">Through <br></br>
                  Strategic Funding</div>
                <div className="d-flex EmpoweringBlock">
                  <div className="lineV mt-3"></div>
                  <div className="praEmpowerDiv py-2">
                    <p>
                      Our primary goal is to enhance the accessibility of
                      public funding as a viable financing avenue for startups,
                      SMEs, and associations among our esteemed members.
                      We achieve this by facilitating seamless connections
                      between the Nordic region and Europe at large.
                      Additionally, we spearhead similar initiatives in regions like the Baltics,
                      Ukraine, and beyond, including Africa and Latin America,
                      where there is burgeoning interest in collaboration.
                    </p>
                    <a
                      href="/about"
                      className="d-block text-decoration-none text-center btnRegisterSummit24"
                    >Read More</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
              <div className="EmoImgBigSmall d-grid d-none d-md-block d-lg-block">
                <div className="big"><img loading='lazy' src={emp_big} /></div>
                <div className="small"><img loading='lazy' src={emp_small} /></div>
              </div>
              <div className='d-block d-md-none d-lg-none'>
              <div ><img loading='lazy' src={emp_mobile} /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutSection