import { Outlet, useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { ToastContainer, toast } from 'react-toastify';
import ReactGA from "react-ga4";
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";


function App() {
  const location = useLocation();
  const pathsToHideHeaderFooter = ["/ENFA-AI/sign-up",'/ENFA-AI/thank-you'];
  const shouldHideHeaderFooter = pathsToHideHeaderFooter.includes(location.pathname);
  
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: `${window.location.pathname == '/' ? 'HOME PAGE' : window.location.pathname.toUpperCase().replace('/', "") + ' ' + 'PAGE'}` });
  }, [window.location.pathname])
  
  return (
    <div className="App">    
      {!shouldHideHeaderFooter && <Header />}
      <Outlet />
      {!shouldHideHeaderFooter && <Footer />}
      <ToastContainer />
    </div>
  );
}

export default App;
