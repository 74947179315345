import React from 'react'
import offices from './../../assets/images/about/ourOffice.png'
import './Aboutus.css';
const OfficeComponent = () => {
    return (
        <div style={{ backgroundColor: '#0007B9' }}>
            <div className="overflow-hidden img" style={{ display: 'flex', justifyContent: 'center' }}>
                <img loading='lazy' src={offices} alt="" />
            </div>
        </div>
  )
}

export default OfficeComponent