import React from 'react'
import './speaker.css'

import img1 from '../../../assets/images/Summit2023/speakers/Marianne Borgen - Mayor of Oslo.jpeg'
import img2 from '../../../assets/images/Summit2023/speakers/Trond-Riiber-Knudsen.jpg'
import img3 from '../../../assets/images/Summit2023/speakers/Curt Rice.jpg'
import img4 from '../../../assets/images/Summit2023/speakers/vincent.fleischer-sintef.no.jpg'
import img5 from '../../../assets/images/Summit2023/speakers/Dr. Liliia Honcharevych.jpg'
import img6 from '../../../assets/images/Summit2023/speakers/Mehraz Rafat.png'
import img7 from '../../../assets/images/Summit2023/speakers/Preben Fjellaker Mathisen - Innovation Norway.jpeg'
import img8 from '../../../assets/images/Summit2023/speakers/Øystein Garfors.jpg'
import img9 from '../../../assets/images/Summit2023/speakers/Christina Wiig.png'
import img10 from '../../../assets/images/Summit2023/speakers/Jørn Haanæs .jpeg'
import img11 from '../../../assets/images/Summit2023/speakers/Carina Hundhammer.jpeg'
import img12 from '../../../assets/images/Summit2023/speakers/Henrik Botten Taubøll.jpeg'
import img13 from '../../../assets/images/Summit2023/speakers/Sean Jayven Ramiro Aguinaldo.jpg'
import img14 from '../../../assets/images/Summit2023/speakers/Jørn Casper Owre.jpeg'
import img15 from '../../../assets/images/Summit2023/speakers/Elisabeth Fosseli Olsen.jpeg'
import img16 from '../../../assets/images/Summit2023/speakers/Anniken Fjelberg.jpg'
import img17 from '../../../assets/images/Summit2023/speakers/Jonas Tesfu.jpeg'
import img18 from '../../../assets/images/Summit2023/speakers/Raul Saenz de Inestrillas.jpeg'
import img19 from '../../../assets/images/Summit2023/speakers/Homa Hasan(1).jpeg'
import img20 from '../../../assets/images/Summit2023/speakers/Anne Hege Strand.jpeg'
import img21 from '../../../assets/images/Summit2023/speakers/Nicola Vita.jpg'
import img22 from '../../../assets/images/Summit2023/speakers/Arine Huijboom.jpeg'
import img23 from '../../../assets/images/Summit2023/speakers/Astrid Sundberg.jpeg'
import img24 from '../../../assets/images/Summit2023/speakers/Carlos Franco Uliaque.jpg'
import img25 from '../../../assets/images/Summit2023/speakers/Ignatius Oli.jpg'
import img26 from '../../../assets/images/Summit2023/speakers/Alessandro Carbone.jpg'
import img27 from '../../../assets/images/Summit2023/speakers/Ajay Goyal.webp'

// import sp1 from '../../../assets/images/Summit2023/speakers/Ahmed Hassan.jpeg'
// import sp3 from '../../../assets/images/Summit2023/speakers/Arine Huijboom.jpeg'
// import sp10 from '../../../assets/images/Summit2023/speakers/Heidi Aven.jpeg'
// import sp15 from '../../../assets/images/Summit2023/speakers/Lisa-Cooper-portrett-mangfoldsledelse-videocation.jpeg'
// import sp19 from '../../../assets/images/Summit2023/speakers/Reinaart Pretorius-1.jpg'



import polygon_white from '../../../assets/images/polygon-white.svg'
import speakerTrangle from '../../../assets/images/speaker-trangle.svg'
import commingSoon from '../../../assets/images/summit/commingSoon.png'

const speakersData = [
  {
    imgSrc: img1,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Marianne Borgen',
    description: "Mayor of Oslo"
  },
  {
    imgSrc: img2,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Trond Riiber Knudsen',
    description: "Investor, TRK Group"
  },
  {
    imgSrc: img3,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Curt Rice',
    description: "Former Rector at Oslo Metropolitan University and Norwegian University of Life Sciences"
  },
  {
    imgSrc: img4,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Vincent Wego Fleischer',
    description: "Executive Vice President, Strategy and Communications at SINTEF"
  },
  {
    imgSrc: img5,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Dr. Liliia Honcharevych',
    description: "Chargé d`affaires of the Embassy of Ukraine in Norway"
  },
  {
    imgSrc: img6,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Mehraz Rafat',
    description: "Senior Advisor, Department for​​​​​​​ Partnerships and Shared Prosperity at Norwegian Agency for Development Cooperation (Norad)"
  },
  {
    imgSrc: img7,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Preben Fjellaker Mathisen',
    description: "Senior Advisor for Startups at Innovation Norway"
  },
  {
    imgSrc: img8,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Øystein Garfors',
    description: "Head of Knowledge and Learning at Norwegian Agency for Exchange Cooperation (Norec) - Norway´s only competence centre for international exchange"
  },
  {
    imgSrc: img9,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Christina Wiig',
    description: "Head of Corporate Partnerships at StartupLab Oslo"
  },
  {
    imgSrc: img10,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Jørn Haanæs',
    description: "Investment Director and Partner at Katapult Accelerator"
  },
  {
    imgSrc: img11,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Carina Hundhammer',
    description: "Ex Abelia, CEO at Norin Research AS"
  },
  {
    imgSrc: img12,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Henrik Botten Taubøll',
    description: "Ex WeWork, Co-Founder at Proptech Norway"
  },
  {
    imgSrc: img13,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Sean Jayven Ramiro Aguinaldo',
    description: "Program Manager at Startup Norway"
  }, 
  {
    imgSrc: img14,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Jørn-Casper Owre',
    description: "Green Shift Senior Adviser NORCAP, part of the Norwegian Refugee Council (NRC)"
  },
  {
    imgSrc: img15,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Elisabeth Fosseli Olsen',
    description: "Former Director of Humanitarian Resilience and Response NORCAP, Researcher at the University of Oslo"
  },
  {
    imgSrc: img16,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Anniken Fjelberg',
    description: "Ex Horizon Europe, NYBY"
  },
  {
    imgSrc: img17,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Jonas Tesfu',
    description: "Founding Trustee at Pangea Trust"
  },
  {
    imgSrc: img18,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Raul Saenz De Inestrillas',
    description: "Founder at Sizigia Consulting"
  },
  {
    imgSrc: img19,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Homa Hasan',
    description: "Humanitarian Worker"
  },
  {
    imgSrc: img20,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Anne Hege Strand',
    description: "Researcher at FAFO, an independent social science research foundation"
  },
  {
    imgSrc: img21,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Nicola Vita',
    description: "Co-founder and head of international projects of EXEOLAB, Italy"
  },
  {
    imgSrc: img22,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Arine Huijboom',
    description: "Founder and Director at Netwerkpro, Netherlands"
  },
  {
    imgSrc: img23,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Astrid Sundberg',
    description: "Ex ODA, Advisory Board Member at Humans for Humans"
  },
  {
    imgSrc: img24,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Carlos Franco Uliaque',
    description: "Director of Geinnova, Spain"
  },
  {
    imgSrc: img25,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Ignatius Oli',
    description: "Chairperson of ISEDE, Germany"
  },
  
  {
    imgSrc: img26,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Allesandro Carbone',
    description: "Director of T-HAP, Cyprus"
  },
  {
    imgSrc: img27,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Ajay Goyal',
    description: "Founder and CEO at ForestSat"
  }
];
export const SpeakerCard = ({ imgSrc , imgSpeakerTraingle, name, position, description }) => (
    <div className="speakersCards23">
      <div className="position-relative overflow-hidden img">
        {imgSrc ? (
          <>
            <img loading='lazy' src={imgSrc} alt={name} />
            <div className="position-absolute trangleSpear">
              <img loading='lazy' src={imgSpeakerTraingle} alt="" />
            </div>
          </>
        ) : (
          <>
          <img loading='lazy' src={commingSoon} alt={name} />
          <div className="position-absolute trangleSpear">
            <img loading='lazy' src={imgSpeakerTraingle} alt="" />
          </div>
        </>
             
        )}
      </div>
      {imgSrc && (
        <>
          <h3 className='nameofspeaker'>{name}</h3>
          <p className='desofspeaker'>{description}</p>
        </>
      )}
    </div>
  );
const SummitSpeaker23 = () => {
    return (
        <section className="position-relative summitSpeaker23">
            <div className="summitSpeaker23BG">
                <div className="container">
                    {/* <div className="meetTeamArrowW">
                        <img loading='lazy' src={polygon_white} alt="Arrow" />
                    </div> */}
                    <div className="titleMeetTeam ourSpearkText23">Our Speakers</div>
                    <div className="row">
                        {speakersData.map((speaker, index) => (
                            <div key={index} className="col-6 col-lg-3 col-xl-3">
                                <SpeakerCard {...speaker} />
                            </div>
                        ))}
                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SummitSpeaker23