import React from 'react'
import './PartnerUkraineSetion.css'
const PartnerUkraineSetion = () => {
  return (
    <section className="position-relative partnerUkraineSetion">
    <div className="container">
      <div className="mx-auto text-center position-relative cardUkraine">
        <div className="hdPartnerUkraine">PARTNER FOR UKRAINE</div>
        <p className="praPartnerUkraine">
          Collaborations across Europe and Nordics for helping Ukrainian
          friends and partners through access to national, regional and EU
          wide grants.
        </p>
        <hr style={{border:'1px solid #135dac'}}></hr>
        <p className="praPartnerUkraine">
          At ENFA you can contribute to rebuilding a resilient Ukraine
        </p>
        <a
            href="/summit24"
            className="d-block mx-auto text-decoration-none text-center readMoreBtn"
          >Read More</a>
        </div>
      </div>
    </section>
  )
}

export default PartnerUkraineSetion