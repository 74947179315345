import React from 'react'
import './RegistrationBanner.css'
const RegistrationBanner = () => {
  return (
    <section className="registrationBannerSection">
    <div className="registrationBannerSectionBG">
      <div className="h-100 container">
        <div className='d-flex align-items-center justify-content-center flex-column h-100 gap-3'>
          <h2
            className="  text-center mx-auto bannerHeadingReg"
          >
            Sign Up for ENFA Summit 2024
          </h2>
          <div className="d-flex flex-column flex-lg-row d-md-flex d-lg-flex SummitAboutSecDates">
            <span>Date: 14 - 15th October</span>
            <span>Venue: Berns, Stockholm</span>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default RegistrationBanner