import './ThankYou.css';
import checkbox from '../../assets/images/checkbox.svg'
import { NavLink } from 'react-router-dom';

const ThankYou = () => {
  return (
    <div className="p-0 m-0" style={{ height: '100vh', backgroundColor: '#F4F5FA' }} >
      <div className="d-flex align-items-center justify-content-center h-100 flex-column" style={{ gap: '30px' }}>
        <img src={checkbox} className='text-center' />
        <div className='d-flex flex-column' style={{ gap: '30px' }}>
          <div className='thanks-title text-center'>
            Thank You for Signing Up!
          </div>
          <div className='desc'>
            Welcome to <strong>Grants AI.</strong> We're thrilled to have you join our community. Your sign-up is complete, and you're now part of a vibrant network.
          </div>
        </div>
        <NavLink to="/" className=" nav-link navItem btnokay cursor-pointer" >Access Dashboard</NavLink>


      </div>
    </div>
  )
}

export default ThankYou