import React from 'react'
import emp_big from './../../../assets/images/emp-big.png'
import emp_small from './../../../assets/images/emp-small.png'
import img1 from './../../../assets/images/Summit2023/Summit23_1.png'
import './Recap.css'
import './RecapSection.css'
const RecapComponent = () => {
    return (
        <>
            {/* <section >
                <section className="position-relative summit2023About" >
                    <div className="container-fluid">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div
                                className="d-flex justify-content-center align-items-center h-100 flex-column summitbannerContent"
                            >
                                <span className="d-block text-uppercase"
                                style={{fontSize:'16px',fontWeight:'500'}}>Success Stories</span>
                                <h1 style={{ fontSize: '48px' ,fontWeight:'700'}}>2023 Recap</h1>
                            </div>
                        </div>
                        <div className="flex-container">
                            <div className="item four">
                                <p className='paraTag'>
                                    The Summit 2023 in Oslo served as a pivotal platform for crucial dialogue among key stakeholders. By bringing together delegates from Norway and over 25 countries, it facilitated discussions on mobilizing funds more efficiently and effectively. This dialogue was instrumental in laying the groundwork for systemic interventions aimed at maximizing the impact of projects and summit2023ships.
                                </p>
                                <p className='paraTag'>
                                    The contributions of the delegates were invaluable. Their generosity in sharing resources and insights not only enriched the discussions but also paved the way for the creation of ENFA - the Euro Nordic Funding Alliance. This alliance signifies a collaborative effort among strong investment, grants, and network organizations, which is essential for driving meaningful change and maximizing project impact.
                                </p>
                                <p className='paraTag'>
                                    The outcomes of the summit are promising. The establishment of ENFA as the official platform for partnerships and projects indicates a commitment to ongoing collaboration and resource-sharing. Moreover, the engagement of participating organizations in discussions for collaborative grant applications demonstrates a proactive approach to leveraging collective strengths for greater success.
                                </p>
                            </div>
                            <div className="item two">
                                <img loading='lazy' className='recapImg' src={img1} alt="" />
                            </div>
                        </div>

                        <div className="flex-container">
                            <div className="item two">
                                <img loading='lazy' className='recapImg' src={img1} alt="" />
                            </div>
                            <div className="item four">
                                <p className='paraTag'>
                                    The Summit 2023 in Oslo served as a pivotal platform for crucial dialogue among key stakeholders. By bringing together delegates from Norway and over 25 countries, it facilitated discussions on mobilizing funds more efficiently and effectively. This dialogue was instrumental in laying the groundwork for systemic interventions aimed at maximizing the impact of projects and partnerships.
                                </p>
                                <p className='paraTag'>
                                    The contributions of the delegates were invaluable. Their generosity in sharing resources and insights not only enriched the discussions but also paved the way for the creation of ENFA - the Euro Nordic Funding Alliance. This alliance signifies a collaborative effort among strong investment, grants, and network organizations, which is essential for driving meaningful change and maximizing project impact.
                                </p>
                                <p className='paraTag'>
                                    The outcomes of the summit are promising. The establishment of ENFA as the official platform for partnerships and projects indicates a commitment to ongoing collaboration and resource-sharing. Moreover, the engagement of participating organizations in discussions for collaborative grant applications demonstrates a proactive approach to leveraging collective strengths for greater success.
                                </p>
                            </div>
                        </div>


                    </div>
                </section>
            </section> */}
            <section className="position-relative summit2023UkraineSetionBlue">
                <div className="container">
                    <div className="mx-auto text-center position-relative cardUkraineSummit2023Blue">
                        <div className="hdsummit2023Ukraine">
                        <h5 className='modal-title headingTitleUkraine'>Ukraine Delegation</h5>
                        The Ukrainian delegation's impact was noteworthy, showcasing a dozen social projects and earning respect for their professionalism, passion, and resilience in self-help. Their contributions added substance to the discussions, highlighting the positive impact nations can have on a global scale.
                        </div>
                    </div>
                </div>
                <section/>
                <section className="position-relative summit2023UkraineSetionYellow">
                <div className="container">
                    <div className="mx-auto text-center position-relative cardUkraineSummit2023Yellow">
                        <p className="prasummit2023Ukraine">
                            The summit witnessed the inception of a transparent and democratic collaborative network. This network, with a focus on social mobility, environmental conservation, inclusion, and innovation projects worth Euro 100 million, combines strengths and knowledge from various entities, including the EU, Norway, regional funders, and private foundations. The aim is to maximize the positive impact of social, educational, innovation, and health projects through collaborative efforts.
                        </p>
                    </div>
                </div>
                </section>
            </section>
        </>
    )
}

export default RecapComponent