import React from 'react'
import './summitTestimonial.css'
// import interview_1 from './../../../assets/images/media/Eiendommegler.mp4';
// import interview_2 from './../../../assets/images/media/Startup_Lab.mp4'
import Testimonial from './../../../assets/images/Summit2023/Testimonial.png'
import commingSoon from '../../../assets/images/summit/commingSoon.png'
import ReactPlayer from 'react-player/youtube'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const SummitTestimonial = () => {
    const videoLinks = ['https://www.youtube.com/embed/QkFFpazQOCQ?si=Qz1sLzGsg0FLiLXn', 'https://www.youtube.com/embed/iOo6I554W3I?si=g_-ObD6DS82f_1PJ', 'https://www.youtube.com/embed/UafsQifxiCE?si=ufhtF4ISRVuJ4T3U', 'https://www.youtube.com/embed/U7Clpacf8PI?si=jiKJlk07mK94gcGZ', 'https://www.youtube.com/embed/GtFDt-PFXSQ?si=kb7pDGs3-CUddJgc'];
    const [index, setIndex] = React.useState(0);
    return (
        <>
            <section id='deskTopView'>
                <section className="position-relative summit2023About" style={{ padding: '40px' }}>
                    {/* <div className="container-fluid">
                        <div
                            className="d-flex justify-content-center align-items-center h-100 flex-column summitbannerContent"
                            style={{ marginTop: '20px', marginBottom: '4%' }}>
                            <span className="d-block text-uppercase"
                            >Testimonials</span>
                        </div>
                        <div className="slideshow">
                            <div
                                className="slideshowSlider "
                                style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                            >
                                {videoLinks.map((itemVde, index) => (
                                    <div
                                        className="slideCard"
                                        key={index}

                                    >
                                        <div className="card cardTestimonial">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-12 col-xl-12" style={{ backgroundColor: '#161c2d' }}>
                                                        <div >
                                                            <iframe width="100%" height="900" src={`${itemVde}?autoplay=0&mute=0&controls=0&modestbranding=1&showinfo=0&rel=0`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> */}
                     <div
                        className="d-flex justify-content-center align-items-center h-100 flex-column summitbannerContent"
                        style={{ marginTop: '30px' }}>
                        <span className="d-block text-uppercase"
                        >Testimonials</span>
                    </div>

                    <div className="slideshow">
                        <div
                            className="slideshowSlider"
                            style={{ transform: `translate3d(${-index * 50}%, 0, 0)` }}
                        >
                            {videoLinks.map((itemVde, index) => (
                                <div
                                    className={`${videoLinks.length-1==index ? 'slideVideo' : 'slideVideo'}`}
                                    key={index}
                                >
                                    <iframe  width="100%" height="900" src={`${itemVde}?autoplay=0&mute=0&controls=0&modestbranding=1&showinfo=0&rel=0`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                                </div>
                            ))}
                        </div>

                        <div className="slideshowDots">
                            {videoLinks.map((_, idx) => (
                                <div
                                    key={idx}
                                    className={`slideshowDotPartner${index === idx ? " active" : ""}`}
                                    onClick={() => {
                                        setIndex(idx);
                                    }}
                                ></div>
                            ))}
                        </div>
                    </div>
                
                </section>
                {/* <section className="summit2023About" >
                    <div className="container-fluid"></div>
                    <div className="slideshowDots">
                        {videoLinks.map((_, idx) => (
                            <div
                                key={idx}
                                className={`slideshowDot${index === idx ? " active" : ""}`}
                                onClick={() => {
                                    setIndex(idx);
                                }}
                            ></div>
                        ))}
                    </div>
                </section> */}
            </section>

            <section id='mobileView' >
                <div className="container-fluid">
                    <div
                        className="d-flex justify-content-center align-items-center h-100 flex-column summitbannerContent"
                        style={{ marginTop: '20px', marginBottom: '4%' }}>
                        <span className="d-block text-uppercase"
                        >Testimonials</span>
                    </div>
                    <div style={{ position: "relative", textAlign: 'center', marginBottom: '4%' }}>
                        <Carousel responsive={responsive}>
                            {videoLinks.map((itemVde, index) =>
                                <div className="container" key={index}>
                                    <iframe width="100%" height="500" src={`${itemVde}?autoplay=0&mute=0&controls=0&modestbranding=1&showinfo=0&rel=0`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                                </div>
                            )
                            }
                        </Carousel>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SummitTestimonial