import React, { useState } from 'react'
import './TicketPricesSection.css'
import Summary from '../Summary/Summary';
import RegistrationForm from '../../Registration/RegistrationForm/RegistrationForm';

const ticketsData = [
  {
    id: 1,
    type: 'Gold Pass',
    price: '750',
    currency: 'EUR',
    originalPrice: '1,200 EUR',
    features: [
      { name: '2-Day Full Access', description: 'All sessions and workshops' },
      { name: 'Venue and ENFA Ai Tool access', description: 'Global ENFA network, meeting bookings, and more' },
      { name: 'Gala Dinner on the 14th', description: 'Networking with participants and guests' },
      { name: 'Consortium Building', description: 'Present, gain visibility, and form partnerships' },
      { name: 'Exhibition Stand', description: 'Showcase products and innovations.' },
    ],
  },
  {
    id: 2,
    type: 'Silver Pass',
    price: '580',
    currency: 'EUR',
    originalPrice: '1,200 EUR',
    features: [
      { name: '2-Day Full Access', description: 'All sessions and workshops' },
      { name: 'Venue and ENFA Ai Tool access', description: 'Global ENFA network, meeting bookings, and more' },
      { name: 'Gala Dinner on the 14th', description: 'Networking with participants and guests' },
      { name: 'Consortium Building', description: 'Present, gain visibility, and form partnerships' },
    ],
  },
  {
    id: 3,
    type: 'Bronze Pass',
    // subType: '(2nd Person)',
    price: '400',
    currency: 'EUR',
    originalPrice: '1,200 EUR',
    features: [
      { name: '2-Day Full Access', description: 'All sessions and workshops' },
      { name: 'Venue and ENFA Ai Tool access', description: 'Global ENFA network, meeting bookings, and more' },
      { name: 'Consortium Building', description: 'Present, gain visibility, and form partnerships.' },
    ],
  },
  {
    id: 4,
    type: 'Gala Dinner Only',
    price: '225',
    currency: 'EUR',
    originalPrice: '1,200 EUR',
    features: [
      { name: 'Evening at Berns Ballroom', description: null },
      { name: 'Includes', description: 'Elegant dinner and drinks' },
      { name: 'Networking', description: 'Meet the event participants and more guests - government officials, representatives from the Nordic philanthropy and funding organizations, investors, world-class startups, etc.' },
    ],
  },
];
const TicketCard = ({ id, type, subType, price,currency, originalPrice, features, onAddToCart, onRemoveFromCart, cart, openPopup }) => {
  const quantity = cart[id] || 0;
  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
      <div className="position-relative overflow-hidden ticketCards">
        {/* <div className="position-absolute earlyBird">
          <p className="m-0">Early Bird</p>
        </div> */}
        <div className="d-flex h-100 flex-column justify-content-between ticketContent">
          <div className="cntTop">
            <div className="text-uppercase silverPass">
              {type}
              <span className="text-uppercase silverPassSubtype">{subType}</span>
            </div>
            {/* <div className="text-center smallPrice">{originalPrice}</div> */}
            <div className='text-center price'>
            <span className="bigPrice">{price}</span> <sup className="eurCurrency">{currency}</sup>
            </div>
            <ul className="ulTicket">
              {features.map((feature, index) => (
                <li key={index}>
                  <b>{feature.name}:</b> {feature.description}
                </li>
              ))}
            </ul>
          </div>
          <div className="d-flex mx-auto text-decoration-none text-center quantity">
            <button className='minusBtn cursor-pointer' onClick={() => onRemoveFromCart(id)}>-</button>
            <input type="text" value={quantity} readOnly />
            <button className='plusBtn cursor-pointer' onClick={() => onAddToCart(id)}>+</button>
          </div>
          <a onClick={() => { onAddToCart(id); openPopup(); }}
            className="d-block mx-auto text-decoration-none text-center btnRegisterTicket"
          >Register now</a>
        </div>
      </div>
    </div>
  );
};
const TicketPricesSection = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [cart, setCart] = useState({});
  const showBackground = false;
  const openPopup = () => {
    document.body.style.overflow = 'hidden'; 
    setPopupOpen(true);
  };
  const closePopup = () => {
    document.body.style.overflow = ''; 
    setPopupOpen(false);
  };
  const handleAddToCart = (itemId) => {
    setCart((prevCart) => ({
      ...prevCart,
      [itemId]: (prevCart[itemId] || 0) + 1,
    }));
  };

  const handleRemoveFromCart = (itemId) => {
    setCart((prevCart) => {
      const updatedCart = { ...prevCart };
      if (updatedCart[itemId] > 0) {
        updatedCart[itemId] -= 1;
      }
      return updatedCart;
    });
  };
  return (
    <div style={{ backgroundColor: '#161c2d' }}>
      <section className="ticketPricesSection">
        
          <div className="container">
            <div className='text-center mx-auto   align-items-center justify-content-center'>
              <div className="textTicket text-uppercase">Tickets</div>
              {/* <div className="textPrices">Early bird prices!</div> */}
              <p className="praApprox">
                All prices are ex VAT and ticket fee (3%). Pricing in EURO is
                approx.
              </p>
            </div>
            <div className="row g-4 justify-content-center">
              {ticketsData.map((ticket, index) => (
                <TicketCard
                  key={index}
                  {...ticket}
                  cart={cart}
                  onAddToCart={handleAddToCart}
                  onRemoveFromCart={handleRemoveFromCart}
                  openPopup={openPopup}
                />
              ))}
            </div>

            {/* <div className="additionalOffer">
            <div className="innerBorder">
              <div className="text-uppercase textadditinalOffers">
                Additional offers
              </div>
              <ul className="ulTicket ulOffers">
                <li>
                  Option for 20 VIP guests - Free pass (mostly for our speakers
                  and some high-level guests).
                </li>
                <li>
                  Option for 20 discounted guests - 10-15% discounted Silver
                  tickets
                </li>
                <li>
                  Accommodation: there are rooms available in the same venue
                  with a discount - the original price is approximately 150 EUR
                  per night. We will also add a few more options nearby and some
                  a bit further outside of the downtown.
                </li>
                <li>City tour</li>
              </ul>
              <a
                href="#"
                className="d-block text-decoration-none text-center btnRegisterTicket"
              >Become Member</a>
            </div>
          </div> */}
          </div>
      
      </section>
      {ticketsData.some(item => cart[item.id] > 0) && (
        <>
          {isPopupOpen && (
            <div className="popup-overlay registrationForm">
              <div className="popup">
                <button className="close-popup" onClick={closePopup}>
                </button>
                <RegistrationForm title={`Registration Form`} showBackground={false} closePopup={closePopup} />
              </div>
            </div>
          )}
          <div className="container summaryContainer">
            <div className="SummarySec">
              <div className="innerBorder">
                <Summary cart={cart} ticketsData={ticketsData} title={`Summary`} />
              </div>
            </div>
          </div></>
      )}
    </div>
  )
}

export default TicketPricesSection