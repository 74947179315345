import React from "react";

const Loader = () => {
  return (
    <div>
     Loading...
    </div>
  );
};

export default Loader;
