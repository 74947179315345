import React from 'react'
import './Summit24Sec2.css'
import left from '../../../assets/images/summit/summitAboutLeftBFNew.png'
import leftMobile from '../../../assets/images/summit/summitAboutLeftMobile.png'


const Summit24Sec2 = () => {
  return (
    <section className="position-relative summitAbout">
    <div className="d-flex flex-column flex-xl-row d-md-flex d-lg-flex summitAboutBG">
      <div className="leftImg d-flex">
        {/* <img loading='lazy' src={left} className='d-none d-md-none d-lg-none  d-xl-block' style={{height:'100%'}}/> */}
        <img loading='lazy' src={left} width={'100%'}/>
      </div>
      <div className="rightContent container">
        <span className="d-block text-uppercase textYear">This year's theme</span>
        <h4 className='text-uppercase'>BUILD WHAT MATTERS</h4>
        <p>
        On October 14 - 15, 2024, over 200 impact-driven companies and individuals from across the Nordics, Europe, and beyond will gather in Stockholm to exchange knowledge, network, and concepts and develop projects and partnerships for 2025 grant applications. 
        </p>
        <p>
        If you're a startup, investment company, investor, NGO, not-for-profit, research institution, researcher, university, or municipality, with impactful concepts and value propositions seeking funding, this event is for you. 
        </p>
        {/* <p>
          Europe offers an enormous grant pool of several hundred billion
          kroner per year, and many qualified entities miss out on important
          opportunities, due to lack of knowledge of what is available out
          there
        </p>
        <div className="textBold">Be bold. Be courageous. Be there.</div> */}
      </div>
    </div>
    </section>
  )
}

export default Summit24Sec2