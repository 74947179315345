import React from 'react'
import './SummitAttend.css';
import emp_big from '../../../assets/images/Summit2023/Summit23_1_1.JPEG'
import emp_small from '../../../assets/images/Summit2023/summit23_small.JPEG'
const SummitAttendees = () => {
    return (
        // <section className="summithomeAboutSection">
        //     <div className="summithomeAboutSectionBG">
        //         <div className="container">
        //             <div
        //                 className="d-flex flex-column flex-lg-row d-md-flex d-lg-flex align-items-center justify-content-center summithomeCounter"
        //             >
        //                 <div className="text-center summitcounter">
        //                     <b>18,000+</b>
        //                     <p>Attendees since 2016</p>
        //                 </div>
        //                 <div className="text-center summitcounter">
        //                     <b>50%+</b>
        //                     <p>C-Level Executives</p>
        //                 </div>
        //                 <div className="text-center summitcounter">
        //                     <b>98,6%</b>
        //                     <p>Recommend OBF 2021</p>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>

        <section className="summit2023AboutSection">
        <div className="summit2023AboutSectionBG">
          <div className="container">
            <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="EmoImgBigSmallSummit23">
                  <div className="bigSummit23"><img loading='lazy' src={emp_big} /></div>
                  <div className="smallSummit23"><img loading='lazy' src={emp_small} /></div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-6">
                <div className="summit2023EnfaBox">
               
                  <div className="titleEmpoweringSummit23">
                  BUSINESS <br></br> SUMMIT 2023
                  </div>
                  <div className="text-uppercase textEnfaSummit23">150+ Delegates <br></br>from 25+ Countries</div>
                  <div className="d-flex EmpoweringBlockSummit23">
                    <div className="lineVSummit23 mt-3"></div>
                    <div className="praEmpowerDivSummit23 py-2">
                      <p>
                      The outcomes of the summit were promising. The establishment of ENFA as a result of the summit to become the official platform for impact- driven partnerships and projects indicates a commitment to ongoing collaboration and resource- sharing. Moreover, the engagement of participating organizations in discussions for collaborative grant application demonstrates a proactive approach to leveraging collective strength of greater success.
                      </p>
                      {/* <a
                        href="#."
                        className="d-block text-decoration-none text-center btnRegisterSummit24"
                      >Read More</a> */}
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>
    )
}

export default SummitAttendees