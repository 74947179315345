import axios from 'axios';
import CryptoJS from 'crypto-js';
import { apiendpoints } from "../appConstants";

const encryptionKey = 'ENFA@AI-PRIVATE-KEY';

const axiosInstance = axios.create({
    baseURL: apiendpoints.baseURL,
  });

  const customPayloadFormat = {
    data: null,
    timestamp: null,
  };
  
  // Encrypt request data before sending
axiosInstance.interceptors.request.use((config) => {
    if (config.data) {
      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(config.data), encryptionKey).toString();
      config.data = {
        payload:encryptedData,
      };
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
  
  // Decrypt response data before resolving
axiosInstance.interceptors.response.use((response) => {
    if (response.data.data) {
      const decryptedData = CryptoJS.AES.decrypt(response.data.data, encryptionKey).toString(CryptoJS.enc.Utf8);
      console.log("Turbo ~ axiosInstance.interceptors.response.use ~ decryptedData:", decryptedData);
      response.data.data = JSON.parse(decryptedData);
    }
    return response;
  }, (error) => {
    return Promise.reject(error);
  });

  export default axiosInstance;
