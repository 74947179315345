import React from 'react'
import './Aboutus.css'


import OfficeComponent from './OfficeComponent'
import BannerComponent from './Banner/BannerComponent'
import { MissionComponent } from './Mission/MissionComponent'

import TeamComponent from './Teams/TeamComponent'
import { PartnerComponent } from './Partners/PartnerComponent'



const About = () => {
   
    return (
        <div className='body'>
            <section className="aboutSection">
               
                <BannerComponent/>

                <MissionComponent/>

                <OfficeComponent/>

                <PartnerComponent/>
            </section>

            <TeamComponent/>
        </div>
    )
}

export default About