import React, { useState } from 'react'
import './style.css'
import logo from './../../assets/images/Footer/Footer/logoFooter.svg'
import imageLocation from './../../assets/images/Footer/Communication/location_on.png'
import imageCall from './../../assets/images/Footer/Communication/call.png'
import imagesemail from './../../assets/images/Footer/Communication/email.png'
import linkedin from './../../assets/images/Footer/Footer/linkedin-icon.png'
import facebook from './../../assets/images/Footer/Footer/facebook-3.png'
import instagram from './../../assets/images/Footer/Footer/instagram-2-1.png'
import { toast } from 'react-toastify'
imagesemail
const Footer = () => {
  const [email, setEmail] = useState('');
  const [isToastVisible, setIsToastVisible] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = (e) => {
    e.preventDefault();
    if (!email.trim()) {
      toast.error('Please enter your email address');
      setIsToastVisible(true);
      setTimeout(() => {
        setIsToastVisible(false);
      }, 6000);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);

    if (isValidEmail) {
      toast.success('Subscription successful!');
    } else {
      toast.error('Invalid email address');
    }
    setIsToastVisible(true);
    setTimeout(() => {
      setIsToastVisible(false);
    }, 6000);

  };
  return (
    <div className="footerSection">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <div className="footlogo">
              <a href="#">
                <img loading='lazy' src={logo} alt="" />
              </a>
              <p>
                ENFA is an introduction to Norwegian funding programs, social &
                climate impact investment funds and global wealth philanthropic
                funding. Also partnerships with nordic , EU and EEA grants.<br />
                So You can present your concept notes through high impact
                collaboration breakout rooms and build partnerships for joint
                proposals in the upcoming years.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <div className="footLinks">
              <span className="d-block text-uppercase">CONTACT INFO</span>
              <div className="d-flex footAddres">
                <div className="icon">
                  <img loading='lazy' src={imageLocation} alt="" />
                </div>
                <p>27 Oscars Gate, OSLO</p>
              </div>
              <div className="d-flex footAddres">
                <a href="mailto:contact@en-fa.org" className='text-decoration-none d-flex  mb-0'>
                  <div className="icon">
                    <img loading='lazy' src={imagesemail} alt="" />
                  </div>
                  <p>contact@en-fa.org</p>
                </a>
              </div>
              <div className="d-flex footAddres">
                <a href="tel:+4791623579" className='text-decoration-none  d-flex mb-0'>
                  <div className="icon">
                    <img loading='lazy' src={imageCall} alt="" />
                  </div>
                  <p>+47 916 23579</p>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
            <div className="footLinks">
              <div className="footAddres">
                <span className="d-block text-uppercase">COMPANY</span>
                <a className="text-decoration-none d-block" href="/about"
                >About Us</a>
                <a className="text-decoration-none d-block" href="#"
                >Terms & Conditions</a>
                <a className="text-decoration-none d-block" href="#"
                >Privacy Policy</a>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-4 col-xl-4">
            <div className="footLinks">
              <span className="d-block text-uppercase NewLetterText"
              >Join our Newsletter</span>
              <form className="flex-column flex-lg-row flex-xl-row d-flex newleeterForm">
                <input
                  className="inpNewLetter"
                  type="text"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <button className="btnSubscribe cursor-pointer" onClick={handleSubscribe} disabled={isToastVisible} >
                  Subscribe
                </button>
              </form>
              <div className="d-flex footSocial">
                <a className="text-decoration-none text-center d-block" target='_blank' href="https://www.linkedin.com/company/euronordicfundingalliance/"
                ><img loading='lazy' src={linkedin}></img></a>
                <a className="text-decoration-none text-center d-block" href="#"
                ><img loading='lazy' src={facebook}></img></a>
                <a className="text-decoration-none text-center d-block" target='_blank' href="https://www.instagram.com/euronordicfundingalliance/"
                ><img loading='lazy' src={instagram}></img></a>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center copyDiv">Copyright ©2024, All Rights Reserved by ENFA.</div>
      </div>
    </div >
  )
}

export default Footer