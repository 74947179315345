import React from 'react'
import './FundingImpactSection.css'
const FundingImpactSection = () => {
  return (
    <section className="homeFundingImpactSection">
    <div className="homeFundingImpactSectionBG">
      <div className="h-100 container">
        <div className='d-flex align-items-center justify-content-center flex-column h-100'>
          <h3 style={{ color: " #fff" }} className='text-uppercase'>Funding Impact</h3>
          <h2
            className="text-uppercase  text-center mx-auto bannerHeading"
          >
            ENFA Business <br></br> Summit 2024
          </h2>
          <div className="d-flex flex-column flex-lg-row d-md-flex d-lg-flex FundingImpactDates">
            <span>Date: 14 - 15th October</span>
            <span>Venue: Berns, Stockholm</span>
          </div>
          <a
            href="/summit24"
            className="d-block mx-auto text-decoration-none text-center btnSeeEvent"
          >See the event</a>
        </div>
      </div>
    </div>
  </section>
  )
}

export default FundingImpactSection