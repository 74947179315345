import React from 'react'
import './SummitSpeaker.css'
import adamImg from '../../../assets/images/summit/adam-grant.png'
import polygon_white from '../../../assets/images/polygon-white.svg'
import speakerTrangle from '../../../assets/images/speaker-trangle.svg'
import commingSoon from '../../../assets/images/summit/comminsonnNew.png'
import commingSoonMobile from '../../../assets/images/summit/comminsoonmob.png'

import image1 from '../../../assets/images/summit2024/speakers/Marianne-Borgen-Mayor-of-Oslo.png'
import image2 from '../../../assets/images/summit2024/speakers/Trond-Riiber-Knudsen.png'
import image3 from '../../../assets/images/summit2024/speakers/Curt-Rice.png'
import image4 from '../../../assets/images/summit2024/speakers/vincent-fleischer.png'
import image5 from '../../../assets/images/summit2024/speakers/Dr-Liliia-Honcharevych.png'
import image6 from '../../../assets/images/summit2024/speakers/Mehraz-Rafat.png'
import image7 from '../../../assets/images/summit2024/speakers/Preben-Fjellaker-Mathisen.png'
import image8 from '../../../assets/images/summit2024/speakers/Øystein-Garfors.png'
import image9 from '../../../assets/images/summit2024/speakers/Christina-Wiig.png'
import image10 from '../../../assets/images/summit2024/speakers/Jørn-Haanæs.png'
import image11 from '../../../assets/images/summit2024/speakers/Carina-Hundhammer.png'
import image12 from '../../../assets/images/summit2024/speakers/Henrik-Botten-Taubøll.png'
import image13 from '../../../assets/images/summit2024/speakers/Sean-Jayven.png'
import image14 from '../../../assets/images/summit2024/speakers/Jørn-Casper-Owre.png'
import image15 from '../../../assets/images/summit2024/speakers/Elisabeth-Fosseli-Olsen.png'
import image16 from '../../../assets/images/summit2024/speakers/Anniken-Fjelberg.png'
import image17 from '../../../assets/images/summit2024/speakers/Jonas-Tesfu.png'
import image18 from '../../../assets/images/summit2024/speakers/Raul-Saenz-De-Inestrillas.png'
import image19 from '../../../assets/images/summit2024/speakers/Homa-Hasan.png'
import image20 from '../../../assets/images/summit2024/speakers/Anne-Hege-Strand.png'
import image21 from '../../../assets/images/summit2024/speakers/Nicola-Vita.png'
import image22 from '../../../assets/images/summit2024/speakers/Arine-Huijboom.png'
import image23 from '../../../assets/images/summit2024/speakers/Carlos-Franco-Uliaque.png'
import image24 from '../../../assets/images/summit2024/speakers/Ignatius-Oli.png'
import image25 from '../../../assets/images/summit2024/speakers/Alessandro-Carbone.png'
import image26 from '../../../assets/images/summit2024/speakers/Ajay-Goyal.png'
import image27 from '../../../assets/images/summit2024/speakers/Astrid-Sundberg.png'

const speakersData = [
  {
      imgSrc: image1,
      imgSpeakerTraingle: speakerTrangle,
      name: 'Marianne Borgen',
      position: 'Mayor of Oslo',
      description:"",
  },
  {
      imgSrc: image2,
      imgSpeakerTraingle: speakerTrangle,
      name: 'Trond Riiber Knudsen',
      position: 'Investor, TRK Group',
      description:"",
  },
  {
      imgSrc: image3,
      imgSpeakerTraingle: speakerTrangle,
      name: 'Curt Rice',
      position: 'Former Rector at Oslo Metropolitan University and Norwegian University of Life Sciences',
      description:"",
  },
  {
    imgSrc: image4,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Vincent Wego Fleischer',
    position: 'Executive Vice President, Strategy and Communications at SINTEF',
    description:"",
  },
  {
    imgSrc: image5,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Dr. Liliia Honcharevych',
    position: 'Chargé d`affaires of the Embassy of Ukraine in Norway',
    description:"",
  },
  {
    imgSrc: image6,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Mehraz Rafat',
    position: 'Senior Advisor, Department for Partnerships and Shared Prosperity at Norwegian Agency for Development Cooperation (Norad)',
    description:"",
  },
  {
    imgSrc: image7,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Preben Fjellaker Mathisen',
    position: 'Senior Advisor for Startups at Innovation Norway',
    description:"",
  },
  {
    imgSrc: image8,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Øystein Garfors',
    position: 'Head of Knowledge and Learning at Norwegian Agency for Exchange Cooperation (Norec) - Norway´s only competence center for international exchange',
    description:"",
  },
  {
    imgSrc: image9,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Christina Wiig',
    position: 'Head of Corporate Partnerships at StartupLab Oslo',
    description:"",
  },
  {
    imgSrc: image10,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Jørn Haanæs',
    position: 'Investment Director and Partner at Katapult Accelerator',
    description:"",
  },
  {
    imgSrc: image11,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Carina Hundhammer',
    position: 'Ex Abelia, CEO at Norin Research AS',
    description:"",
  },
  {
    imgSrc: image12,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Henrik Botten Taubøll',
    position: 'Ex WeWork, Co-Founder at Proptech Norway',
    description:"",
  },
  {
    imgSrc: image13,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Sean Jayven Ramiro Aguinaldo',
    position: 'Sean Jayven Ramiro Aguinaldo',
    description:"",
  },
  {
    imgSrc: image14,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Jørn-Casper Owre',
    position: 'Green Shift Senior Adviser NORCAP, part of the Norwegian Refugee Council (NRC)',
    description:"",
  },
  {
    imgSrc: image15,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Elisabeth Fosseli Olsen',
    position: 'former Director of Humanitarian Resilience and Response NORCAP, Researcher at the University of Oslo',
    description:"",
  },
  {
    imgSrc: image16,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Anniken Fjelberg',
    position: 'Ex Horizon Europe, NYBY',
    description:"",
  },
  {
    imgSrc: image17,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Jonas Tesfu',
    position: 'Founding Trustee at Pangea Trust',
    description:"",
  },
  {
    imgSrc: image18,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Raul Saenz De Inestrillas',
    position: 'Founder at Sizigia Consulting',
    description:"",
  },
  {
    imgSrc: image19,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Homa Hasan',
    position: 'Humanitarian Worker',
    description:"",
  },
  {
    imgSrc: image20,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Anne Hege Strand',
    position: 'Researcher at FAFO, an independent social science research foundation',
    description:"",
  },
  {
    imgSrc: image21,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Nicola Vita',
    position: 'Co-founder and head of international projects of EXEOLAB, Italy',
    description:"",
  },
  {
    imgSrc: image22,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Arine Huijboom',
    position: 'Founder and Director at Netwerkpro, Netherlands',
    description:"",
  },
  {
    imgSrc: image27,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Astrid Sundberg',
    position: 'Ex ODA, Advisory Board Member at Humans for Humans',
    description:"",
  },
  {
    imgSrc: image23,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Carlos Franco Uliaque',
    position: 'Director of Geinnova, Spain',
    description:"",
  },
  {
    imgSrc: image24,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Ignatius Oli',
    position: 'Chairperson of ISEDE, Germany',
    description:"",
  },
  {
    imgSrc: image25,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Allesandro Carbone',
    position: 'Director of T-HAP, Cyprus',
    description:"",
  },
  {
    imgSrc: image26,
    imgSpeakerTraingle: speakerTrangle,
    name: 'Ajay Goyal',
    position: 'Founder and CEO at ForestSat',
    description:"",
  }
];
const SpeakerCard = ({ imgSrc, imgSpeakerTraingle, name, position, description }) => (
  <div className="speakersCards">
    <div className="position-relative overflow-hidden img">
      {imgSrc ? (
        <>
          <img loading='lazy' src={imgSrc} alt={name} />
          <div className="position-absolute trangleSpear">
            <img loading='lazy' src={imgSpeakerTraingle} alt="" />
          </div>
        </>
      ) : (
        <>
          <img loading='lazy' src={commingSoon} alt={name} className='d-none d-md-block d-lg-block' style={{ height: '100%' }} />
          <img loading='lazy' src={commingSoonMobile} className='d-block d-md-none d-lg-none' />
          {/* <div className="position-absolute trangleSpear">
            <img loading='lazy' src={imgSpeakerTraingle} alt="" />
          </div> */}
        </>

      )}
    </div>
    {imgSrc && (
        <>
          <h3>{name}</h3>
          <p>
            {position}. {description}
          </p>
          {/* <a href="#" className="d-block text-center text-decoration-none anchReadmore">
            Read More
          </a> */}
        </>
      )}
  </div>
);
const SummitSpeaker = () => {
  return (
    <section className="position-relative summitSpeaker" style={{display:"none"}}>
      <div className="summitSpeakerBG">
        <div className="container">
          {/* <div className="meetTeamArrowW">
                        <img loading='lazy' src={polygon_white} alt="Arrow" />
                    </div> */}
          <div className="titleMeetTeam ourSpearkText">Our Speakers</div>
          <div className="row">
            {speakersData.map((speaker, index) => (
              <div key={index} className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 my-3 column px-3">
                <SpeakerCard {...speaker} />
              </div>
            ))}

          </div>
        </div>
      </div>
    </section>
  )
}

export default SummitSpeaker