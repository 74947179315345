import React, { useState, useEffect, useRef } from 'react'
import './style.css'
import logo from './../../assets/images/header/enfacompanylogo.svg'
import { Link, NavLink, ScrollRestoration } from 'react-router-dom'


const Header = (props) => {
  const handleScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const header = document.getElementById("dynamic");
    const add_class_on_scroll = () => header.classList.add("fadeWhite");
    const add_class_on_scroll_shadow = () => header.classList.add("boxShadow");
    const remove_class_on_scroll = () => header.classList.remove("fadeWhite");
    const remove_class_on_scroll_shadow = () => header.classList.remove("boxShadow");
    if(winScroll > 40){
      add_class_on_scroll();
      add_class_on_scroll_shadow();
    }else{
      remove_class_on_scroll();
      remove_class_on_scroll_shadow();
    }
  };
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
  
    // Remove the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);
 
  return (
    <>

    {/* DESKTOP VIEW */}
      <div className="header" id="dynamic">
        <div className="container-fluid" >

          <div className="row" >
            <div className="col-sm-2 col-md-2 col-lg-1" style={{maxWidth:'100%'}} >
              {/* <a href="" className="d-block logo logoEnfa" >
                <img loading='lazy' src={logo} className="logoImage" />
              </a> */}
              <NavLink onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} className="nav-link d-block logo logoEnfa"  aria-current="page" to='/'>  <img loading='lazy' src={logo} className="logoImage" /> </NavLink>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-1" style={{maxWidth:'50%'}} >
          
            </div>
            <div className="col-sm-6 col-md-6 col-lg-10" style={{top:'30',maxWidth:'100%'}} >
            
            <ul id='deskMobile' className="navbar-nav ml" style={{ color: 'white',display:'flex',flexDirection:'row',justifyContent:'end',marginTop:'28px', whiteSpace:'nowrap'}} >
                      <li className="nav-item mr-1" style={{marginRight:'5%'}}>
                        
                        {/* <Link activeStyle={{ backgroundColor: #349eff; }}   className="nav-link" aria-current="page" to='/'
                        >Home</Link> */}
                        <NavLink onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} exact="true" style={({ isActive }) => {
                          return isActive ? {border: '1px solid #dee2e6',borderRadius: '5px',background: 'rgb(63 64 69/50%)',padding: '7px' } : {}
                        }} className="nav-link navItem"  aria-current="page" to='/'> Home </NavLink>
                        
                      </li>
                      <li className="nav-item mr-1" style={{marginRight:'5%'}}>
                        <NavLink onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} exact="true" style={({ isActive }) => {
                          return isActive ? {border: '1px solid #dee2e6',borderRadius: '5px',background: 'rgb(63 64 69/50%)',padding: '7px'  } : {}
                        }} className="nav-link navItem"  aria-current="page" to='/about'> About Us</NavLink>
                      </li>
                      <li className="nav-item mr-1" style={{marginRight:'5%'}}>
                        {/* <Link className="nav-link" to="/summit23">Summit 2023</Link> */}
                        <NavLink onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} exact="true" style={({ isActive }) => {
                          return isActive ? {border: '1px solid #dee2e6',borderRadius: '5px',background: 'rgb(63 64 69/50%)',padding: '7px'  } : {}
                        }} className="nav-link navItem"  aria-current="page" to='/summit23'> Summit 2023 </NavLink>
                      </li>
                      <li className="nav-item mr-1" style={{marginRight:'5%'}}>
                        <NavLink onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} exact="true" style={({ isActive }) => {
                          return isActive ? {border: '1px solid #dee2e6',borderRadius: '5px',background: 'rgb(63 64 69/50%)',padding: '7px'  } : {}
                        }} className="nav-link navItem"  aria-current="page" to="/summit24">Summit 2024</NavLink>
                      </li>
                      <li className="nav-item mr-1" style={{marginRight:'5%'}}>
                        <NavLink onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} exact="true" style={({ isActive }) => {
                          return isActive ? {border: '1px solid #dee2e6',borderRadius: '5px',background: 'rgb(63 64 69/50%)',padding: '7px'  } : {}
                        }} className="nav-link navItem"  aria-current="page" to="/membership">Membership</NavLink>
                      </li>
                      <li className="nav-item mr-1" style={{marginRight:'5%'}}>
                        <NavLink onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} exact="true" style={({ isActive }) => {
                          return isActive ? {border: '1px solid #dee2e6',borderRadius: '5px',background: 'rgb(63 64 69/50%)',padding: '7px'  } : {}
                        }} className="nav-link navItem"  aria-current="page" to="/funding-overview">Funding Overview</NavLink>
                      </li>
                      <li >
                      <a href="/ENFA-AI/sign-up" rel="noopener noreferrer">
                        <button className="btnLogin cursor-pointer" >Login</button>
                      </a>
                     </li>
                    </ul>
                   
              <nav className="navbar navbar-expand-lg navbar-light " id='navMobile'>
                <div className="container-fluid">
                  <button style={{ position: 'absolute', right: '0', top: '25px', color: 'white', backgroundColor: 'white', fontSize: '13px' }} className="navbar-toggler menuBuuton btn-close-blue " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="offcanvas offcanvas-end" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                    <div className="offcanvas-header" style={{ backgroundColor: '#161c2dfc', border: '1px solid #161c2dfc' }}>
                      <button type="button" className="btn-close btn-close-white" style={{ color: 'white' }} data-bs-dismiss="offcanvas" aria-label="Close"></button>

                    </div>
                    <div style={{ height: '1px', color: 'white', background: 'white' }}></div>
                    <div className="offcanvas-body" style={{ backgroundColor: '#161c2dfc', border: '1px solid #161c2dfc' }}>
                      <ul className="navbar-nav" style={{ color: 'white', gap: '10px' }}>
                        <li className="nav-item mr-1" >
                          <NavLink style={({ isActive }) => {
                          return isActive ? {borderRadius: '5px',background: 'rgb(63 64 69/50%)',padding: '10px'} : {}
                        }}onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} className="nav-link ml-15" data-bs-dismiss="offcanvas" aria-current="page" to='/'
                          >Home</NavLink>
                        </li>
                        <li className="nav-item mr-1">
                          <NavLink style={({ isActive }) => {
                          return isActive ? {borderRadius: '5px',background: 'rgb(63 64 69/50%)',padding: '10px'} : {}
                        }} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} className="nav-link ml-15" data-bs-dismiss="offcanvas" to="/about">About Us</NavLink>
                        </li>
                        <li className="nav-item mr-1">
                          <NavLink style={({ isActive }) => {
                          return isActive ? {borderRadius: '5px',background: 'rgb(63 64 69/50%)',padding: '10px'} : {}
                        }} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} className="nav-link ml-15" data-bs-dismiss="offcanvas" to="/summit23">Summit 2023</NavLink>
                        </li>
                        <li className="nav-item mr-1">
                          <NavLink style={({ isActive }) => {
                          return isActive ? {borderRadius: '5px',background: 'rgb(63 64 69/50%)',padding: '10px'} : {}
                        }} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} className="nav-link ml-15" data-bs-dismiss="offcanvas" to="/summit24">Summit 2024</NavLink>
                        </li>
                        <li className="nav-item mr-1">
                          <NavLink style={({ isActive }) => {
                          return isActive ? {borderRadius: '5px',background: 'rgb(63 64 69/50%)',padding: '10px'} : {}
                        }} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} className="nav-link ml-15" data-bs-dismiss="offcanvas" to="/membership">Membership</NavLink>
                        </li>
                        <li className="nav-item mr-1">
                          <NavLink style={({ isActive }) => {
                          return isActive ? {borderRadius: '5px',background: 'rgb(63 64 69/50%)',padding: '10px'} : {}
                        }} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} className="nav-link ml-15" data-bs-dismiss="offcanvas" to="/funding-overview">Funding Overview</NavLink>
                        </li>
                        <a href="/ENFA-AI/sign-up" rel="noopener noreferrer" style={{padding:'20px 0px 20px 0px'}} >
                          <button className="btnLogin cursor-pointer" >Login</button>
                        </a>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header