import React from 'react';
import './Summary.css'
const Summary = ({ cart, ticketsData, title }) => {
    const renderTicketSummary = () => {
        let totalAmount = 0;

        const selectedTickets = ticketsData.filter((ticket) => cart[ticket.id] > 0);

        const ticketRows = selectedTickets.map((ticket) => {
            const quantity = cart[ticket.id] || 0;
            const amount = parseFloat(ticket.price) * quantity;
            totalAmount += amount;

            return (
                <tr key={ticket.id} >
                    <td>{ticket.type}</td>
                    <td>{quantity}</td>
                    <td>{amount.toFixed(2)}</td>
                </tr>
            );
        });

        return (
            <>
                {ticketRows}
                {/* <tr>
                    <td colSpan="2">Ticket fee</td>
                    <td>75</td>
                </tr>
                <tr>
                    <td colSpan="2">Total ex. VAT</td>
                    <td>{totalAmount.toFixed(2)}</td>
                </tr>
                <tr>
                    <td colSpan="2">Total VAT</td>
                    <td>{(totalAmount * 0.25).toFixed(2)}</td>
                </tr> */}
                <tr className='rowBorder'>
                    <td colSpan="2">Total</td>
                    <td>{(totalAmount).toFixed(2)}</td>
                </tr>
            </>
        );
    };

    return (
        <div className="summary">
            <h3>{title}</h3>
            <table>
                <thead>
                    <tr className='rowBorder'>
                        <th>Tickets</th>
                        <th>Quantity</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTicketSummary()}
                </tbody>
            </table>
        </div>
    );
};

export default Summary;
