import './AboutCity.css'

import React from "react";
import image1 from "../../../assets/images/AboutCity/image1.png";
import image2 from "../../../assets/images/AboutCity/image2.png";
import image3 from "../../../assets/images/AboutCity/image3.png";
const AboutCityCardsModal = ({ sendId }) => {

    return (
        <div className="card border-0">
            <div className="d-flex flex-column gap-4 px-lg-3">
                {sendId === 3 &&
                    <>
                        <div className='text-center mb-md-5 mt-1 px-md-5'>
                            <img loading='lazy' src={image2} alt="" />
                        </div>
                        <div className='title-small'>
                            Travel Info
                        </div>
                        <div className='title-large'>
                            To Stockholm by train
                        </div>
                        <div className='body'>
                            <p>
                                Stockholm Centralstation is the city's main hub of transportation. So, if you're visiting by train or taking the Arlanda Express from Arlanda airport, chances are this is your last stop. The station is centrally located and is also connected to the T-Centralen subway station. Some regional and national train lines also stop at Stockholm Södra, Stockholm Odenplan, Flemingsburg or Älvsjö Station.
                            </p>
                            <p>
                                Train tickets can only be bought in Stockholm Central station's ticket dispensers or online – there's no staffed ticket office, but there are station hosts should you need any assistance or help. Tour & Tickets in the main hall sells tickets for the MTRX-train though, and you can buy public transport tickets at SL Center located below the ground floor. There are also several restaurants and shops, and lockers for your bags.
                            </p>
                            <p>
                                Stockholm Central station is also connected to Cityterminalen bus depot, the main hub for regional or international bus travelers. The Flygbussarna airport coaches also make a stop here.
                            </p>
                        </div>

                    </>
                }
                {sendId === 2 &&
                    <>
                        <div className='text-center mb-5 mt-1 px-md-5'>
                            <img loading='lazy' src={image3} alt="" />
                        </div>
                        <div className='title-small'>
                            Travel Info
                        </div>
                        <div className='title-large'>
                            Ferries and cruise ships
                        </div>
                        <div className='body'>
                            <p>
                                Traveling to Stockholm by sea? Here's what you need to know.</p>
                            <p>
                                Several major ferry lines operate in the Baltic Sea, and Stockholm's harbors are centrally located. Stockholm is also a popular cruise destination and every year around 300 cruise liners from all over the world arrive it's the harbors. The approach through the beautiful Stockholm archipelago with its 30,000 islands is an experience. Read more about ferries and cruising to Stockholm below or see all vessel calls in the ports of Stockholm here.</p>
                            <p>
                                All inner-city harbors are equipped for international cruise ship arrivals. Their central location makes Stockholm a unique cruise destination with city sights, museums, and galleries, shopping, cafés, and restaurants all within easy reach.
                            </p>
                            <p>
                                Piers in Stockholm
                            </p>
                            <p>
                                Nybroviken 5: Right in the city center, 5 min walk to the Old Town.
                            </p>
                            <p>
                                Skeppsbron: Next to the Royal Palace in the Old Town. Walking distance to most shops and attractions.
                            </p>
                            <p>
                                Mooring Buoy: Right in the Old Town. Walking distance to most shops and attractions.
                            </p>
                            <p>
                                Stadsgården 160: 10 min walk to the Old Town; 20 min to the city center.
                            </p>
                            <p>
                                Stadsgården 165/167: 20 min walk to the Old Town; 30 min to the city center; 10 min by taxi or bus.
                            </p>
                            <p>
                                Värtahamnen 523: 3 km; 10 min by taxi or 20 min by bus.
                            </p>
                            <p>
                                Frihamnen 650: 3 km; 10 min by taxi or 20 min by bus.
                            </p>
                            <p className='pb-3'>
                                Frihamnen 634/638: 3 km; 10 min by taxi or 20 min by bus.
                            </p>
                            <p>
                                Passenger terminals
                            </p>
                            <p>
                                Stockholm Cruise Center, Frihamnen 638
                            </p>
                            <p>
                                Stockholm Cruise Center, Stadsgården 165/167, same building as the Fotografiska museum.
                            </p>
                            <p>
                                Both passenger terminals have toilets, souvenir shops, telephones, post boxes, internet access, and restaurants/cafes that are open during cruise calls.
                            </p>
                        </div>

                    </>
                }
                {sendId === 1  &&
                    <>
                        <div className='text-center mb-5 mt-1 px-md-5'>
                            <img loading='lazy' src={image1} alt="" />
                        </div>
                        <div className='title-small'>
                            Travel Info
                        </div>
                        <div className='title-large'>
                            To and from the airports
                        </div>
                        <div className='body'>
                            <p>
                                All four of Stockholm's airports are connected to downtown by airport coaches as well as municipal alternatives. From Stockholm Arlanda-Airport you can also choose the high-speed train service Arlanda Express.
                            </p>
                            <p>
                                <strong>
                                    Stockholm Arlanda Airport
                                </strong>
                                <br />
                                Stockholm-Arlanda Airport is located 40 kilometers (25 miles) north of Stockholm City
                            </p>
                            <p>
                                The Arlanda express train provides a shuttle service between Stockholm Arlanda Airport and Stockholm Central Station. The platform is easily accessible underneath the arrival terminals. The trains depart every 10 minutes in rush hour traffic, and every 15 minutes at other times. Travel time: 18 minutes. All trains offer free wifi.
                            </p>
                            <p>
                                Flygbussarna Airport Coaches depart every 10–15 minutes between Arlanda Airport and the City Terminal (located next to the Central Station). Travel time from Arlanda: 35–45 minutes. Buses also run to Liljeholmen via Karolinska, Fridhemsplan, and Södermalm (50 min) and to Brommaplan via Kista and Sundbyberg. (54 min). All buses offer free wifi.
                            </p>
                            <p>
                                Commuter trains (operated by the public transport company in Stockholm, SL) to Stockholm depart twice an hour from Arlanda Central Station in Sky City. Total travel time between the airport and Stockholm Central Station is 43 minutes.
                            </p>
                            <p>
                                There are also several companies operating buses from Arlanda, for example, Flixbus and Nettbus.
                            </p>
                            <p>
                                <strong>
                                    Other airports
                                </strong> <br />Bromma Stockholm Airport, Skavsta Airport, and Västerås Airport.
                            </p>
                            <p>
                                Flygbussarna Airport Coaches operates all airports, with departures timed with arrivals and departures at the airports. Travel time: 20 minutes (Bromma), 80 minutes (Skavsta) and 80 minutes (Västerås).
                            </p>
                            <p>
                                <strong>More info</strong>
                            </p>
                            <ul>
                                <li>Stockholm Arlanda Airport</li>
                                <li>Stockholm-Bromma Airport</li>
                                <li>Stockholm-Skavsta Airport</li>
                                <li>Stockholm Västerås Airport</li>
                            </ul>

                        </div>

                    </>
                }
            </div>
        </div>
    );
};



export default AboutCityCardsModal;
