import React, { useState } from 'react'
import RegistrationForm from '../../Registration/RegistrationForm/RegistrationForm';
import './../style.css'
import './summitReg.css'
import empLeft from './../../../assets/images/emp-left.png'
import emp_big from '../../../assets/images/Summit2023/Summit23_1_2.JPEG'


const SummitRegister = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const openPopup = () => {
    document.body.style.overflow = 'hidden'; 
    setPopupOpen(true);
  };
  const closePopup = () => {
    document.body.style.overflow = ''; 
    setPopupOpen(false);
  };
  return (
    <section className="d-flex homeENFASectionSummit2023">
      <div className="leftSide"><img loading='lazy' src={emp_big} className='imgLeft' /></div>
      <div className="rightSide">
        <span className='osla-business'>Oslo Business Summit 2023: Executive Summary</span>
        <p>
          In order to help you be decisive with the most important takeaways
          from Oslo Business Summit 2023, we have created an Executive Summary.
        </p>
        <p>
          Register below to receive the summary of the Oslo business summit 2023 
        </p>
        <a
          href="#."
          className="d-block text-decoration-none text-center btnRegister"
          onClick={() => { openPopup(); }}
          >Register Now</a>
            {isPopupOpen && (
            <div className="popup-overlay registrationForm">
              <div className="popup">
                <button className="close-popup" onClick={closePopup}>
                </button>
                <RegistrationForm title={`Registration Form`} showBackground={false} closePopup={closePopup} isSummit23={true}/>
              </div>
            </div>
          )}
      </div>
    </section>
  )
}

export default SummitRegister