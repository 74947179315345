import React from 'react'
import './MembershipBanner.css'
import bannerVideo from '../../../assets/images/homeBanner/LandingPageHeroImage1.mp4'
import ReactPlayer from 'react-player'
const MembershipBanner = () => {
    return (
        <section className="membershipBannerSection">
            <ReactPlayer
                id="bgVideo"
                url={bannerVideo}
                playing
                playsinline
                muted
                loop
                width="100%"
                height="100%"
                config={{
                    file: {
                        attributes: {
                            loading: 'lazy'
                        }
                    }
                }}
            />
            <div className="membershipBannerSectionBG">
                <div className="h-100 container">
                    <div className='d-flex align-items-center justify-content-center flex-column h-100 gap-3 membershipContent'>
                        <h2
                            className="text-center mx-auto bannerHeadingMembership"
                        >
                            ENFA MEMBERSHIPS
                        </h2>
                        <h3 style={{ color: " #fff", textShadow: '10px 0px 16px #000', fontSize: '16px' }}>ARE FOR</h3>
                        <p className='MembershipbannerSection'>Startups I SMEs I Associations I Institutions I Freelance Project Managers</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MembershipBanner