import React from 'react'
import MembershipBanner from './MembershipBanner/MembershipBanner'
import ENFABusiness from './ENFABusiness/ENFABusiness'
import ENFAService from './ENFAService/ENFAService'
import ENFAVideo from './ENFAVideo/ENFAVideo'
import OrganisationSection from './OrganisationSection/OrganisationSection'
import MembershipForm from './MembershipForm/MembershipForm'

const Membership = () => {
  return (
    <>
      <MembershipBanner />
      {/* <ENFAVideo /> */}
      <ENFAService />
      {/* <ENFABusiness /> */}
      <OrganisationSection />
      <MembershipForm showBackground={true}/>
    </>
  )
}

export default Membership