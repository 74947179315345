import React from 'react'
import './Overview.css'
const OverviewComponent = () => {
    return (
        <section className="overviewContent">
       
        <div className="container">
          <div className="row pb-2 g-3">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h3 className="text-uppercase title overviewtitles">Nordic Grants Examples</h3>
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <td className="first-td"><strong>NordForsk:</strong> Provides funding for collaborative Nordic research projects, ranging from EUR 100,000 to EUR 3 million.</td>
                            <td className="text-center align-middle"><a href="https://www.nordforsk.org/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Nordic Culture Fund:</strong> Supports cultural projects with grants ranging from EUR 10,000 to EUR 300,000.</td>
                            <td className="text-center align-middle"><a href="https://www.nordiskkulturfond.org/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Nordic Innovation:</strong> Offers funding for innovation and business development projects, ranging from EUR 50,000 to EUR 1 million.</td>
                            <td className="text-center align-middle"><a href="https://www.nordicinnovation.org/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Swedish Research Council (VR):</strong> Funds scientific research with grants from SEK 1 million to SEK 30 million.</td>
                            <td className="text-center align-middle"><a href="https://www.vr.se/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Finnish Cultural Foundation:</strong> Provides grants for cultural and social projects, ranging from EUR 5,000 to EUR 200,000.</td>
                            <td className="text-center align-middle"><a href="https://www.skr.fi/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>The Norwegian Research Council::</strong> Offers research funding from NOK 1 million to NOK 50 million.</td>
                            <td className="text-center align-middle"><a href="https://www.forskningsradet.no/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Icelandic Research Fund:</strong> Provides funding for research and innovation projects, ranging from ISK 500,000 to ISK 20 million.</td>
                            <td className="text-center align-middle"><a href="https://www.rannis.is/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Danish National Research Foundation:</strong> Supports high-quality research projects with grants from DKK 1 million to DKK 20 million.</td>
                            <td className="text-center align-middle"><a href="https://www.dg.dk/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h3 className="text-uppercase title overviewtitles">European Grants Examples</h3>
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <td className="first-td"><strong>Horizon Europe:</strong> Provides funding for research and innovation projects, with grants ranging from EUR 100,000 to EUR 5 million.</td>
                            <td className="text-center align-middle"><a href="https://ec.europa.eu/programmes/horizon2020/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>European Regional Development Fund (ERDF):</strong> Supports regional development projects with grants ranging from EUR 50,000 to EUR 2 million.</td>
                            <td className="text-center align-middle"><a href="https://ec.europa.eu/regional_policy/en/funding/erdf/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>European Social Fund (ESF):</strong> Offers funding for social inclusion and employment projects, with grants ranging from EUR 10,000 to EUR 500,000.</td>
                            <td className="text-center align-middle"><a href="https://ec.europa.eu/esf/home.jsp" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Erasmus+:</strong> Provides funding for education, training, youth, and sport projects, with grants ranging from EUR 5,000 to EUR 1 million.</td>
                            <td className="text-center align-middle"><a href="https://ec.europa.eu/programmes/erasmus-plus/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td><strong>Marie Skłodowska-Curie Actions (MSCA):</strong> Supports research mobility and training with grants ranging from EUR 50,000 to EUR 2 million.</td>
                            <td className="text-center align-middle"><a href="https://ec.europa.eu/research/mariecurieactions/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>European Innovation Council (EIC) Accelerator:</strong> Offers funding for breakthrough innovations with grants ranging from EUR 500,000 to EUR 2.5 million.</td>
                            <td className="text-center align-middle"><a href="https://ec.europa.eu/research/eic/index.cfm" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Creative Europe:</strong> Supports cultural and creative projects with grants ranging from EUR 10,000 to EUR 500,000.</td>
                            <td className="text-center align-middle"><a href="https://ec.europa.eu/programmes/creative-europe/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>European Space Agency (ESA) Funding:</strong> Provides funding for space research and technology projects, ranging from EUR 100,000 to EUR 10 million.</td>
                            <td className="text-center align-middle"><a href="https://www.esa.int/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>European Cooperation in Science and Technology (COST):</strong> Supports networking activities for researchers with grants ranging from EUR 10,000 to EUR 130,000 per year.</td>
                            <td className="text-center align-middle"><a href="https://www.cost.eu/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h3 className="text-uppercase title overviewtitles">National Research Funding Examples</h3>
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <td className="first-td"><strong>German Research Foundation (DFG):</strong> Funds scientific research with grants from EUR 50,000 to EUR 3 million.</td>
                            <td className="text-center align-middle"><a href="https://www.dfg.de/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>French National Research Agency (ANR):</strong> Provides funding for research projects with grants from EUR 50,000 to EUR 1.5 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>UK Research and Innovation (UKRI):</strong> Offers research funding from GBP 50,000 to GBP 5 million.</td>
                            <td className="text-center align-middle"><a href="https://www.ukri.org/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Italian National Research Council (CNR):</strong> Provides funding for research and innovation projects, ranging from EUR 10,000 to EUR 500,000.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Spanish Ministry of Science and Innovation:</strong> Supports research projects with grants from EUR 10,000 to EUR 2 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h3 className="text-uppercase title overviewtitles">Examples of Grants for Africa</h3>
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <td className="first-td"><strong>African Development Bank (AfDB):</strong> Provides funding for development projects across Africa, ranging from USD 50,000 to USD 10 million.</td>
                            <td className="text-center align-middle"><a href="https://www.afdb.org/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>African Union Research Grants:</strong> Supports research and innovation projects with grants ranging from USD 100,000 to USD 1 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Bill & Melinda Gates Foundation:</strong> Funds projects in health, agriculture, and education across Africa, with grants ranging from USD 10,000 to USD 5 million.</td>
                            <td className="text-center align-middle"><a href="https://www.gatesfoundation.org/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>UK's Department for International Development (DFID):</strong> Offers funding for development projects in Africa, with grants ranging from GBP 50,000 to GBP 10 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>The Rockefeller Foundation:</strong> Provides grants for health, food, and economic development projects across Africa, ranging from USD 50,000 to USD 1 million.</td>
                            <td className="text-center align-middle"><a href="https://www.rockefellerfoundation.org/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>United Nations Development Programme (UNDP):</strong> Supports sustainable development projects in Africa with grants ranging from USD 10,000 to USD 1 million.</td>
                            <td className="text-center align-middle"><a href="https://www.undp.org/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>World Bank Group:</strong> Offers funding for various development projects in Africa, with grants ranging from USD 100,000 to USD 20 million.</td>
                            <td className="text-center align-middle"><a href="https://www.worldbank.org/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>European Commission's Development Cooperation Instrument (DCI):</strong> Provides funding for development projects in Africa, ranging from EUR 100,000 to EUR 10 million.</td>
                            <td className="text-center align-middle"><a href="https://ec.europa.eu/international-partnerships/funding" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                    </tbody>
                </table>    
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h3 className="text-uppercase title overviewtitles">Examples of US Grants for EU Companies</h3>
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <td className="first-td"><strong>Small Business Innovation Research (SBIR) Program:</strong> Provides funding for early-stage innovation and research with grants ranging from USD 50,000 to USD 1.5 million.</td>
                            <td className="text-center align-middle"><a href="https://www.sbir.gov/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Small Business Technology Transfer (STTR) Program:</strong> Offers funding for cooperative research and development projects, with grants ranging from USD 50,000 to USD 1.5 million.</td>
                            <td className="text-center align-middle"><a href="#" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>National Science Foundation (NSF) Grants:</strong> Funds research and education projects across various fields, with grants ranging from USD 10,000 to USD 5 million.</td>
                            <td className="text-center align-middle"><a href="https://www.nsf.gov/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>National Institutes of Health (NIH) Grants:</strong> Provides funding for biomedical and health-related research, with grants ranging from USD 50,000 to USD 10 million.</td>
                            <td className="text-center align-middle"><a href="https://www.nih.gov/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>US Department of Energy (DOE) Grants:</strong> Supports energy innovation and research projects with grants ranging from USD 50,000 to USD 5 million.</td>
                            <td className="text-center align-middle"><a href="https://www.energy.gov/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>US Agency for International Development (USAID) Grants:</strong> Offers funding for international development projects, with grants ranging from USD 100,000 to USD 10 million.</td>
                            <td className="text-center align-middle"><a href="https://www.usaid.gov/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>US Department of Agriculture (USDA) Grants:</strong> Provides funding for agricultural research and development projects, with grants ranging from USD 10,000 to USD 1 million.</td>
                            <td className="text-center align-middle"><a href="https://www.usda.gov/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>US Environmental Protection Agency (EPA) Grants:</strong> Supports environmental research and development projects, with grants ranging from USD 10,000 to USD 2 million.</td>
                            <td className="text-center align-middle"><a href="https://www.epa.gov/grants" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>US Economic Development Administration (EDA) Grants:</strong> Offers funding for economic development projects, with grants ranging from USD 100,000 to USD 3 million.</td>
                            <td className="text-center align-middle"><a href="https://www.eda.gov/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>US National Aeronautics and Space Administration (NASA) Grants:</strong> Provides funding for space and aeronautics research and development projects, with grants ranging from USD 50,000 to USD 10 million.</td>
                            <td className="text-center align-middle"><a href="https://www.nasa.gov/grants" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                    </tbody>
                </table>    
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h3 className="text-uppercase title overviewtitles">US Grants for Africa</h3>
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <td className="first-td"><strong>USAID Development Grants Program:</strong> Provides funding for development projects in Africa, with grants ranging from USD 100,000 to USD 5 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>President’s Emergency Plan for AIDS Relief (PEPFAR):</strong> Funds health projects focused on combating HIV/AIDS in Africa, with grants ranging from USD 50,000 to USD 2 million.</td>
                            <td className="text-center align-middle"><a href="https://www.pepfar.gov/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Millennium Challenge Corporation (MCC):</strong> Offers funding for economic development projects in African countries, with grants ranging from USD 1 million to USD 10 million.</td>
                            <td className="text-center align-middle"><a href="https://www.mcc.gov/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>US African Development Foundation (USADF) Grants:</strong> Provides funding for community-based development projects in Africa, with grants ranging from USD 10,000 to USD 250,000.</td>
                            <td className="text-center align-middle"><a href="https://www.usadf.gov/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>National Institutes of Health (NIH) Fogarty International Center Grants:</strong> Supports health research and training projects in Africa, with grants ranging from USD 50,000 to USD 1 million.</td>
                            <td className="text-center align-middle"><a href="https://www.fic.nih.gov/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>US Department of State's African Women's Entrepreneurship Program (AWEP):</strong> Offers funding for women-led business projects in Africa, with grants ranging from USD 10,000 to USD 100,000.</td>
                            <td className="text-center align-middle"><a href="https://www.state.gov/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Feed the Future Innovation Labs:</strong> Provides funding for agricultural research and development projects in Africa, with grants ranging from USD 50,000 to USD 5 million.</td>
                            <td className="text-center align-middle"><a href="https://feedthefuture.gov/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>US Trade and Development Agency (USTDA) Grants:</strong> Supports infrastructure development projects in Africa, with grants ranging from USD 100,000 to USD 2 million.</td>
                            <td className="text-center align-middle"><a href="https://www.ustda.gov/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>US Department of Agriculture (USDA) Foreign Agricultural Service Grants:</strong> Offers funding for agricultural and food security projects in Africa, with grants ranging from USD 10,000 to USD 1 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Centers for Disease Control and Prevention (CDC) Global Health Security Agenda Grants:</strong> Provides funding for health security and disease prevention projects in Africa, with grants ranging from USD 50,000 to USD 5 million.</td>
                            <td className="text-center align-middle"><a href="https://www.cdc.gov/globalhealth/security/index.html" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                    </tbody>
                </table>    
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h3 className="text-uppercase title overviewtitles">Nordic Grants for Africa</h3>
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <td className="first-td"><strong>Nordic Development Fund (NDF):</strong> Provides funding for climate resilience and low-carbon development projects in Africa, with grants ranging from EUR 50,000 to EUR 3 million.</td>
                            <td className="text-center align-middle"><a href="https://www.ndf.fi/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Nordic Climate Facility (NCF):</strong> Offers funding for innovative climate solutions in Africa, with grants ranging from EUR 100,000 to EUR 500,000.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Nordic Africa Institute (NAI) Grants:</strong> Supports research and policy analysis projects on African development, with grants ranging from EUR 10,000 to EUR 50,000.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Swedish International Development Cooperation Agency (SIDA):</strong> Provides funding for sustainable development projects in Africa, with grants ranging from SEK 500,000 to SEK 50 million.</td>
                            <td className="text-center align-middle"><a href="https://www.sida.se/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Danish International Development Agency (DANIDA):</strong> Offers funding for development projects in Africa, with grants ranging from DKK 100,000 to DKK 10 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Norwegian Agency for Development Cooperation (NORAD):</strong> Supports projects in health, education, and sustainable development in Africa, with grants ranging from NOK 100,000 to NOK 10 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Finnish Ministry for Foreign Affairs (MFA) Development Cooperation Grants:</strong> Provides funding for development projects in Africa, with grants ranging from EUR 100,000 to EUR 5 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Icelandic International Development Agency (ICEIDA):</strong> Offers funding for development projects in Africa, with grants ranging from ISK 10,000 to ISK 500,000.</td>
                            <td className="text-center align-middle"><a href="https://www.iceida.is/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Nordic Investment Bank (NIB) Environmental Loans:</strong> Provides loans and grants for environmental and sustainable development projects in Africa, with amounts ranging from EUR 1 million to EUR 50 million.</td>
                            <td className="text-center align-middle"><a href="https://www.nib.int/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Nordic Fund for Innovation and Development:</strong> Supports innovative projects in health, education, and technology in Africa, with grants ranging from EUR 50,000 to EUR 1 million.</td>
                            <td className="text-center align-middle"><a href="https://www.nordicinnovation.org/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                    </tbody>
                </table>    
            </div>
            <div className="col-xs-12  col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h3 className="text-uppercase title overviewtitles">Nordic Grants for Ukraine</h3>
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <td className="first-td"><strong>Nordic Development Fund (NDF):</strong> Provides funding for climate resilience and low-carbon development projects in Ukraine, with grants ranging from EUR 50,000 to EUR 3 million.</td>
                            <td className="text-center align-middle"><a href="https://www.ndf.fi/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Nordic Climate Facility (NCF):</strong> Offers funding for innovative climate solutions in Ukraine, with grants ranging from EUR 100,000 to EUR 500,000.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Swedish International Development Cooperation Agency (SIDA):</strong> Provides funding for sustainable development projects in Ukraine, with grants ranging from SEK 500,000 to SEK 50 million.</td>
                            <td className="text-center align-middle"><a href="https://www.sida.se/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Danish International Development Agency (DANIDA):</strong> Offers funding for development projects in Ukraine, with grants ranging from DKK 100,000 to DKK 10 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Norwegian Agency for Development Cooperation (NORAD):</strong> Supports projects in health, education, and sustainable development in Ukraine, with grants ranging from NOK 100,000 to NOK 10 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Finnish Ministry for Foreign Affairs (MFA) Development Cooperation Grants:</strong> Provides funding for development projects in Ukraine, with grants ranging from EUR 100,000 to EUR 5 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Icelandic International Development Agency (ICEIDA):</strong> Offers funding for development projects in Ukraine, with grants ranging from ISK 10,000 to ISK 500,000.</td>
                            <td className="text-center align-middle"><a href="https://www.iceida.is/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Nordic Investment Bank (NIB) Environmental Loans:</strong> Provides loans and grants for environmental and sustainable development projects in Ukraine, with amounts ranging from EUR 1 million to EUR 50 million.</td>
                            <td className="text-center align-middle"><a href="https://www.nib.int/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Nordic Fund for Innovation and Development:</strong> Supports innovative projects in health, education, and technology in Ukraine, with grants ranging from EUR 50,000 to EUR 1 million.</td>
                            <td className="text-center align-middle"><a href="https://www.nordicinnovation.org/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                    </tbody>
                </table>    
            </div>
            <div className="col-xs-12  col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h3 className="text-uppercase title overviewtitles">Nordic Grants for Latin America</h3>
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <td className="first-td"><strong>Nordic Development Fund (NDF):</strong> Provides funding for climate resilience and low-carbon development projects in Latin America, with grants ranging from EUR 50,000 to EUR 3 million.</td>
                            <td className="text-center align-middle"><a href="https://www.ndf.fi/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Nordic Climate Facility (NCF):</strong> Offers funding for innovative climate solutions in Latin America, with grants ranging from EUR 100,000 to EUR 500,000.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Swedish International Development Cooperation Agency (SIDA):</strong> Provides funding for sustainable development projects in Latin America, with grants ranging from SEK 500,000 to SEK 50 million.</td>
                            <td className="text-center align-middle"><a href="https://www.sida.se/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Danish International Development Agency (DANIDA):</strong> Offers funding for development projects in Latin America, with grants ranging from DKK 100,000 to DKK 10 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Norwegian Agency for Development Cooperation (NORAD):</strong> Supports projects in health, education, and sustainable development in Latin America, with grants ranging from NOK 100,000 to NOK 10 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Finnish Ministry for Foreign Affairs (MFA) Development Cooperation Grants:</strong> Provides funding for development projects in Latin America, with grants ranging from EUR 100,000 to EUR 5 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Icelandic International Development Agency (ICEIDA):</strong> Offers funding for development projects in Latin America, with grants ranging from ISK 10,000 to ISK 500,000.</td>
                            <td className="text-center align-middle"><a href="https://www.iceida.is/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Nordic Investment Bank (NIB) Environmental Loans:</strong> Provides loans and grants for environmental and sustainable development projects in Latin America, with amounts ranging from EUR 1 million to EUR 50 million.</td>
                            <td className="text-center align-middle"><a href="https://www.nib.int/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Nordic Fund for Innovation and Development:</strong> Supports innovative projects in health, education, and technology in Latin America, with grants ranging from EUR 50,000 to EUR 1 million.</td>
                            <td className="text-center align-middle"><a href="https://www.nordicinnovation.org/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                    </tbody>
                </table>   
            </div>
            <div className="col-xs-12  col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h3 className="text-uppercase title overviewtitles">Nordic Grants for India</h3>
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <td className="first-td"><strong>Nordic Development Fund (NDF):</strong> Provides funding for climate resilience and low-carbon development projects in India, with grants ranging from EUR 50,000 to EUR 3 million.</td>
                            <td className="text-center align-middle"><a href="https://www.ndf.fi/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Nordic Climate Facility (NCF):</strong> Offers funding for innovative climate solutions in India, with grants ranging from EUR 100,000 to EUR 500,000.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Swedish International Development Cooperation Agency (SIDA):</strong> Provides funding for sustainable development projects in India, with grants ranging from SEK 500,000 to SEK 50 million.</td>
                            <td className="text-center align-middle"><a href="https://www.sida.se/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Danish International Development Agency (DANIDA):</strong> Offers funding for development projects in India, with grants ranging from DKK 100,000 to DKK 10 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Norwegian Agency for Development Cooperation (NORAD):</strong> Supports projects in health, education, and sustainable development in India, with grants ranging from NOK 100,000 to NOK 10 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Finnish Ministry for Foreign Affairs (MFA) Development Cooperation Grants:</strong> Provides funding for development projects in India, with grants ranging from EUR 100,000 to EUR 5 million.</td>
                            <td className="text-center align-middle"><a href="#" className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Icelandic International Development Agency (ICEIDA):</strong> Offers funding for development projects in India, with grants ranging from ISK 10,000 to ISK 500,000.</td>
                            <td className="text-center align-middle"><a href="https://www.iceida.is/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Nordic Investment Bank (NIB) Environmental Loans:</strong> Provides loans and grants for environmental and sustainable development projects in India, with amounts ranging from EUR 1 million to EUR 50 million.</td>
                            <td className="text-center align-middle"><a href="https://www.nib.int/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                        <tr>
                            <td className="first-td"><strong>Nordic Fund for Innovation and Development:</strong> Supports innovative projects in health, education, and technology in India, with grants ranging from EUR 50,000 to EUR 1 million.</td>
                            <td className="text-center align-middle"><a href="https://www.nordicinnovation.org/" target='_blank' className='link'>Learn More</a></td>
                        </tr>
                    </tbody>
                </table>   
            </div>
          </div>
        </div>
      </section>
    )
}

export default OverviewComponent