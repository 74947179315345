import React from 'react'
import './Banner.css'
const BannerComponent = () => {
    console.log(window.scrollTo)
    return (
        <section className="bannerSectionBlueAbout">
        <div className="bannerSectionBlueAboutBG">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div
                  className="d-flex justify-content-center align-items-center h-100 flex-column bannerContentAbout"
                >
                  <span className="d-block text-uppercase textCourageousAbout heading"
                  >Funding Overview</span>
                  {/* <h1> Public Grants, Impact Investment &  International Business Partnership</h1> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default BannerComponent