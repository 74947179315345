import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./MembershipForm.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import arrow from "../../../assets/images/Registration/arrowdown.png";
import axios from "axios";
import ThankyouPopup from "../../Summit24/ThankyouPopup/ThankyouPopup";
import { apiendpoints } from "../../../appConstants";
import CustomSelect from "../../Wrapper/searchableSelect/CustomSelect";
import Loader from "../../Wrapper/Loader/Loader";

const MembershipForm = ({ showBackground, closePopup, isModal }) => {
  const [isThankYouPopupOpen, setThankYouPopupOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  useEffect(() => {
    getCountryList();
  }, []);
  const getCountryList = async () => {
    axios
      .get(apiendpoints.baseURL + apiendpoints.countries)
      .then((response) => {
        const countries = response?.data?.data;
        setCountryOptions(countries);
      })
      .catch((error) => { });
  };
  const openThankYouPopup = () => {
    setThankYouPopupOpen(true);
  };

  const closeThankYouPopup = () => {
    setThankYouPopupOpen(false);
  };
  const membershipState = {
    name: "",
    family_name: "",
    email: "",
    contact_no: "",
    company_name: "",
    role_of_person: "",
    country_id: "",
  };
  const membershipValidationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "First name should only contain letters")
      .max(50, "Max length is 50 characters")
      .required("First name is required"),

    family_name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Surname should only contain letters")
      .max(50, "Max length is 50 characters")
      .required("Surname is required"),

    email: Yup.string()
      .max(50, "Max length is 50 characters")
      .email("Invalid email address")
      .required("Email address is required"),

    contact_no: Yup.string()
      .max(15, "Max length is 15 digits")
      .required("Phone number is required"),

    company_name: Yup.string()
      .max(50, "Max length is 50 characters")
      .required("Company name is required"),

    role_of_person: Yup.string().required("Role is required")
      .max(50, "Max length is 50 characters"),

    country_id: Yup.string().required("Country is required"),
  });


  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={membershipState}
        validationSchema={membershipValidationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const apiUrl = apiendpoints.baseURL + apiendpoints.membership;
          const membershipData = {
            name: values.name ? values.name.trim() : '',
            family_name: values.family_name ? values.family_name.trim() : '',
            email: values.email ? values.email.trim() : '',
            mobile_number: values.contact_no ? values.contact_no.trim() : '',
            country_id: values.country_id ? values.country_id : '',
            company_name: values.company_name ? values.company_name.trim() : '',
            role: values.role_of_person ? values.role_of_person.trim() : '',
          };

          axios
            .post(apiUrl, membershipData)
            .then((response) => {
              setSuccessMessage(response.data.message);
              openThankYouPopup();
              resetForm();
            })
            .catch((error) => {
              openThankYouPopup();
              resetForm();
              setSuccessMessage("");
            })
            .finally(() => {
              openThankYouPopup();
              setSubmitting(false);
              resetForm();
            });
        }}
      >
        {({ values, handleSubmit, isSubmitting }) => (

          <><section className="position-relative MembershipFormSec"style={{ padding: isModal ? '0' : '12px' }}>
            <div
              className={`MembershipFormSecBG ${showBackground ? "showBackground " : ""
                }`}
            >
              <div className={`MembershipFormContainer ${isModal ? "" : "container mt-5 "}`} >
                <div className="d-block mx-auto text-decoration-none text-center ">
                  <h2 className="registrationText">Request Membership</h2>
                </div>
                <div  className={`${isModal ? "scroll scroll1" : ""}`}>
                <div  className={`${isModal ? "contentMembership" : ""}`}>
                    <form onSubmit={handleSubmit} style={{ margin: "20px 0px", padding:'10px' }}>
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          First Name <span className="required-asterisk">*</span>
                        </label>
                        <Field
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder={`Enter your first name`}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="family_name" className="form-label">
                        Surname <span className="required-asterisk">*</span>
                        </label>
                        <Field
                          type="text"
                          id="family_name"
                          name="family_name"
                          className="form-control"
                          placeholder={`Enter your surname`}
                        />
                        <ErrorMessage
                          name="family_name"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email <span className="required-asterisk">*</span>
                        </label>
                        <Field
                          type="text"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder={`Enter your email address`}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="row">
                        <div className=" col-md-6">
                          <PhoneInputField id="contact_no" name="contact_no" />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label className="form-label">
                            Country <span className="required-asterisk">*</span>
                          </label>
                          <CustomSelect
                            id="country_id"
                            name="country_id"
                            options={countryOptions.map((country) => ({
                              value: country.id,
                              label: country.name,
                            }))}
                            placeholder="Select country"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="company_name" className="form-label">
                          Company Name <span className="required-asterisk">*</span>
                        </label>
                        <Field
                          type="text"
                          id="company_name"
                          name="company_name"
                          className="form-control"
                          placeholder={`Enter your company name`}
                        />
                        <ErrorMessage
                          name="company_name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="role_of_person" className="form-label">
                          Role of person{" "}
                          <span className="required-asterisk">*</span>
                        </label>
                        <Field
                          type="text"
                          id="role_of_person"
                          name="role_of_person"
                          className="form-control"
                          placeholder={`Enter role of person`}
                        />
                        <ErrorMessage
                          name="role_of_person"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="d-block mx-auto text-decoration-none text-center mt-5">
                        <button
                          type="submit"
                          className="submitBtn cursor-pointer"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? <Loader /> : 'Submit'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          </>
        )}
      </Formik>
      {isThankYouPopupOpen && (
        <ThankyouPopup
          closeThankYouPopup={closeThankYouPopup}
          successMessage={successMessage}
        />
      )}
    </>
  );
};

const PhoneInputField = ({ id, name }) => (
  <Field name={name}>
    {({ field, form }) => (
      <div className="mb-3">
        <label htmlFor={id} className="form-label">
          Phone Number <span className="required-asterisk">*</span>
        </label>
        <PhoneInput
          id={id}
          name={name}
          country="US"
          international
          value={field.value}
          onChange={(val) => form.setFieldValue(name, val)}
          onBlur={field.onBlur}
          placeholder={`Enter your phone number`}
          className="form-control"
          countryCallingCodeEditable={false}
        />
        <ErrorMessage name={name} component="div" className="text-danger" />
      </div>
    )}
  </Field>
);

export default MembershipForm;
