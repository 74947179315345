import React from 'react'
import './SummitBanner.css'
const SummitBanner = () => {
    return (
        <>
            <section className="summitAboutbannerSection23">
                <div className="summitAboutbannerSection23BG">
                    <div className="h-100 container">
                        <div className='d-flex align-items-center justify-content-center flex-column h-100 gap-3'>
                            <h3 className="d-block text-uppercase textCourageousAbout">Conclusions</h3>
                            <h2
                                className="  text-center mx-auto summit23bannerHeadingsec"
                            >
                                Business Summit 2023 <br></br> was a  Success

                            </h2>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SummitBanner