import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./RegistrationForm.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import arrow from "../../../assets/images/Registration/arrowdown.png";
import axios from "axios";
import { apiendpoints } from "../../../appConstants";
import CustomSelect from "../../Wrapper/searchableSelect/CustomSelect";
import Loader from "../../Wrapper/Loader/Loader";
import { toast } from "react-toastify";

const RegistrationForm = ({ title, showBackground, closePopup, setTosterSuccess, isSummit23}) => {
  const [countryOptions, setCountryOptions] = useState([]);
  useEffect(() => {
    getCountryList();
  }, []);
  const getCountryList = async () => {
    axios
      .get(apiendpoints.baseURL + apiendpoints.countries)
      .then((response) => {
        const countries = response?.data?.data;
        setCountryOptions(countries);
      })
      .catch((error) => { });
  };
  const closeThankYouPopup = () => {
    setThankYouPopupOpen(false);
  };

  const [privateState, setPrivateState] = useState({
    name: "",
    family_name: "",
    email: "",
    contact_no: "",
    country_private: "",
    paymentOption: "Private",
  });

  const [companyState, setCompanyState] = useState({
    name: "",
    family_name: "",
    email: "",
    contact_no: "",
    country_private: "",
    company_name: "",
    organization_number: "",
    address: "",
    zip_code: "",
    city: "",
    country_company: "",
    reference_number: "",
  });
  const [selectedOption, setSelectedOption] = useState("Private");

  useEffect(() => {
    setInitialValues();
  }, [selectedOption]);

  const setInitialValuesForPrivate = () => {
    setPrivateState({
      name: "",
      family_name: "",
      email: "",
      contact_no: "",
      country_private: "",
      paymentOption: "Private",
    });
  };

  const setInitialValuesForCompany = () => {
    setCompanyState({
      name: "",
      family_name: "",
      email: "",
      contact_no: "",
      country_private: "",
      company_name: "",
      organization_number: "",
      address: "",
      zip_code: "",
      city: "",
      country_company: "",
      reference_number: "",
    });
  };
  const setInitialValues = () => {
    if (selectedOption === "Company") {
      setInitialValuesForCompany();
    } else {
      setInitialValuesForPrivate();
    }
  };
  const privateValidationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "First name should only contain letters")
      .max(50, "Max length is 50 characters")
      .required("First name is required"),

    family_name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Surname should only contain letters")
      .max(50, "Max length is 50 characters")
      .required("Surname is required"),

    email: Yup.string()
      .max(50, "Max length is 50 characters")
      .email("Invalid email address")
      .required("Email address is required"),

    contact_no: Yup.string()
      .max(15, "Phone Number must be at most 15 digits")
      .required("Phone Number is required"),

    country_private: Yup.string().required("Country is required"),
    paymentOption: Yup.string(),
  });

  const companyValidationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "First name should only contain letters")
      .max(50, "Max length is 50 characters")
      .required("First name is required"),

    family_name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Surname should only contain letters")
      .max(50, "Max length is 50 characters")
      .required("Surname is required"),

    email: Yup.string()
      .max(50, "Max length is 50 characters")
      .email("Invalid email address")
      .required("Email address is required"),

    country_private: Yup.string().required("Country name is required"),

    contact_no: Yup.string()
      .max(15, "Max length is 15 digits")
      .required("Phone number is required"),

    paymentOption: Yup.string(),

    company_name: Yup.string()
      .max(50, "Max length is 50 characters")
      .required("Company name is required"),

    organization_number: Yup.string()
      .matches(/^[0-9]+$/, "Organization number should only contain digits")
      .max(50, "Max length is 50 characters")
      .required("Organization number is required"),

    address: Yup.string().required("Address is required"),

    zip_code: Yup.string()
      .matches(/^[0-9]+$/, "Invalid Zip Code")
      .max(10, "Max length is 10 digits")
      .required("Zip Code is required"),

    city: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "City should only contain letters")
      .max(50, "Max length is 50 characters")
      .required("City is required"),

    country_company: Yup.string().required("Country name is required"),

    reference_number: Yup.string()
      .matches(/^[0-9]+$/, "Reference / PO number should only contain digits"),
  });

  const handleChangeOption = (option, form) => {
    form.setFieldValue("paymentOption", option, true);
    setSelectedOption(option);
  };
  const PaymentOptionButtons = ({ handleChangeOption, selectedOption }) => {
    return (

      <div className=" button-private-buttons ">
        <Field name="paymentOption">
          {({ form }) => (
            <>
              <div className="row mb-3 ">
                <div className="col-md-6">
                  <button
                    type="button"
                    className={`privateBtn ${selectedOption === "Private" ? "active" : ""
                      } `}
                    onClick={() => handleChangeOption("Private", form)}
                  >
                    <div className="private1">Private</div>
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    type="button"
                    className={`companyBtn ${selectedOption === "Company" ? "active" : ""
                      } `}
                    onClick={() => handleChangeOption("Company", form)}
                  >
                    <div className="company1">Company</div>
                  </button>
                </div>
              </div>
            </>
          )}
        </Field>
      </div>
    );
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={
          selectedOption === "Company" ? companyState : privateState
        }
        validationSchema={() => {
          return selectedOption === "Company"
            ? companyValidationSchema
            : privateValidationSchema;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          //const apiUrl = apiendpoints.baseURL + apiendpoints.register;
          let apiUrl;
          if(isSummit23 == true){
            apiUrl = apiendpoints.baseURL + apiendpoints.registerSummit23;
          }else {
            apiUrl = apiendpoints.baseURL + apiendpoints.register;
          }
          const commonData = {
            name: values.name ? values.name.trim() : '',
            email: values.email ? values.email.trim() : '',
            mobile_number: values.contact_no ? values.contact_no.trim() : '',
            country_id: values.country_private ? values.country_private : '',
          };

          const privateData = {
            ...commonData,
            payee: 2,
          };

          const companyData = {
            ...commonData,
            payee: 1,
            company_name: values.company_name ? values.company_name.trim() : '',
            company_country_id: values.country_company ? values.country_company : '',
            organization_number: values.organization_number ? values.organization_number.trim() : '',
            address: values.address ? values.address.trim() : '',
            zip: values.zip_code ? values.zip_code.trim() : '',
            city: values.city ? values.city.trim() : '',
            reference_number: values.PONum ? values.PONum.trim() : undefined,
          };
          const requestData =
            selectedOption === "Company" ? companyData : privateData;
          axios
            .post(apiUrl, requestData)
            .then((response) => {
              toast.success(response?.data?.message);
              resetForm();
              closePopup();
            })
            .catch((error) => {
              closePopup();
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <section className="position-relative RegistrationFormSec">
            <div
              className={`RegistrationFormSecBG ${showBackground ? "showBackground" : ""
                }`}
            >
              <div className="registrationFormContainer ">
                <div className="d-block mx-auto text-decoration-none text-center ">
                  <h2 className="registrationText">{title}</h2>
                </div>
                <div className="scroll scroll1">
                  <div className="content">
                    <form onSubmit={handleSubmit} style={{ padding: '10px' }}>
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          First Name <span className="required-asterisk">*</span>
                        </label>
                        <Field
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder={`Enter your first name`}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="family_name" className="form-label">
                          Surname <span className="required-asterisk">*</span>
                        </label>
                        <Field
                          type="text"
                          id="family_name"
                          name="family_name"
                          className="form-control"
                          placeholder={`Enter your surname`}
                        />
                        <ErrorMessage
                          name="family_name"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email Address <span className="required-asterisk">*</span>
                        </label>
                        <Field
                          type="text"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder={`Enter your email address`}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="row mb-3 ">
                        <div className="col-md-6">
                          <PhoneInputField id="contact_no" name="contact_no" />
                        </div>
                        <div className="mb-3 col-md-6 ">
                          <label className="form-label">
                            Country <span className="required-asterisk">*</span>
                          </label>
                          <CustomSelect
                            id="country_private"
                            name="country_private"
                            options={countryOptions.map((country) => ({
                              value: country.id,
                              label: country.name,
                            }))}
                            placeholder="Select country"
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="form-label" style={{ fontSize: '17px' }}>
                          Who is paying?{" "}
                          <span className="required-asterisk">*</span>
                        </label>
                        <PaymentOptionButtons
                          handleChangeOption={handleChangeOption}
                          selectedOption={selectedOption}
                        />
                        <ErrorMessage
                          name="paymentOption"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="mb-3">
                        {selectedOption === "Company" && (
                          <>
                            <div className="d-block mx-auto text-decoration-none text-center ">
                              <h2 className="companyText">{selectedOption}</h2>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="company_name" className="form-label">
                                Company Name{" "}
                                <span className="required-asterisk">*</span>
                              </label>
                              <Field
                                type="text"
                                id="company_name"
                                name="company_name"
                                className="form-control"
                                placeholder={`Enter your company name`}
                              />
                              <ErrorMessage
                                name="company_name"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="organization_number"
                                className="form-label"
                              >
                                Organization Number{" "}
                                <span className="required-asterisk">*</span>
                              </label>
                              <Field
                                type="text"
                                id="organization_number"
                                name="organization_number"
                                className="form-control"
                                placeholder={`Enter your organization number`}
                              />
                              <ErrorMessage
                                name="organization_number"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="address" className="form-label">
                                Address <span className="required-asterisk">*</span>
                              </label>
                              <Field
                                type="text"
                                id="address"
                                name="address"
                                className="form-control"
                                placeholder={`Enter your address`}
                              />
                              <ErrorMessage
                                name="address"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mb-3">
                              <div className="row">
                                <div className="col-md-4">
                                  <label htmlFor="zip_code" className="form-label">
                                    Zip <span className="required-asterisk">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    id="zip_code"
                                    name="zip_code"
                                    className="form-control"
                                    placeholder={`Enter your zip`}
                                  />
                                  <ErrorMessage
                                    name="zip_code"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="col-md-8">
                                  <label htmlFor="city" className="form-label">
                                    City{" "}
                                    <span className="required-asterisk">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    id="city"
                                    name="city"
                                    className="form-control"
                                    placeholder={`Enter your city`}
                                  />
                                  <ErrorMessage
                                    name="city"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-3">
                              <label className="form-label">
                                Country <span className="required-asterisk">*</span>
                              </label>
                              <CustomSelect
                                id="country_company"
                                name="country_company"
                                options={countryOptions.map((country) => ({
                                  value: country.id,
                                  label: country.name,
                                }))}
                                placeholder="Select country"
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="reference_number" className="form-label">
                                Reference / PO number (optional)
                              </label>
                              <Field
                                type="text"
                                id="reference_number"
                                name="reference_number"
                                className="form-control"
                                placeholder={`Enter Reference / PO number Number`}
                              />
                              <ErrorMessage
                                name="reference_number"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <div className="d-block mx-auto text-decoration-none text-center mt-5">
                        <button
                          type="submit"
                          className="submitBtn"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? <Loader /> : 'Submit'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
            </div>
          </section>
        )}
      </Formik>
    </>
  );
};

const PhoneInputField = ({ id, name }) => (
  <Field name={name}>
    {({ field, form }) => (
      <div className="mb-3">
        <label htmlFor={id} className="form-label">
          Phone Number<span className="required-asterisk"> *</span>
        </label>
        <PhoneInput
          id={id}
          name={name}
          country="US"
          international
          value={field.value}
          onChange={(val) => form.setFieldValue(name, val)}
          onBlur={field.onBlur}
          placeholder={`Enter your phone number`}
          className="form-control"
          countryCallingCodeEditable={false}
        />
        <ErrorMessage name={name} component="div" className="text-danger" />
      </div>
    )}
  </Field>
);

export default RegistrationForm;
