import React from 'react'
import RegistrationBanner from './RegistrationBanner/RegistrationBanner'
import RegistrationForm from './RegistrationForm/RegistrationForm'

const Registration = () => {
  return (
    <div>
      <RegistrationBanner />
      <RegistrationForm  title={`Registration Form`}/>
    </div>
  )
}

export default Registration