import React, { useEffect, useState } from "react";
import { useField } from "formik";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import './CustomSelect.css';

const animatedComponents = makeAnimated();

const CustomSelectWrapper = ({ children }) => (
    <div className="custom-select-container">
        {children}
    </div>
);

const CustomSelect = ({ options, ...props }) => {
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#007BFF' : 'white',
            color: state.isSelected ? 'white' : 'black',
            '&:hover': {
                backgroundColor: '#007BFF',
                color: 'white',
            },
        }),
        control: (provided) => ({
            ...provided,
            height: '58px',
            border: '1px solid #b5b5b6',
            borderRadius: '5px',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: '15px',
        }),
        input: (provided) => ({
            ...provided,
            height: '100%',
        }),
    };

    const [field, meta, helpers] = useField(props);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if (field.value) {
            const option = options.find((opt) => opt.value === field.value);
            setSelectedOption(option);
        } else {
            setSelectedOption(null); // Reset selected option when field value is empty
        }
    }, [field.value, options]);

    const handleChange = (selected) => {
        setSelectedOption(selected);
        helpers.setValue(selected ? selected.value : "");
    };

    return (
        <div className="custom-select-wrapper">
            <CustomSelectWrapper>
                <Select
                    {...field}
                    {...props}
                    isSearchable
                    components={animatedComponents}
                    value={selectedOption}
                    onChange={handleChange}
                    options={options}
                    styles={{ ...customStyles, ...props.styles }}
                />
            </CustomSelectWrapper>
            {meta.touched && meta.error && (
                <div className="text-danger">{meta.error}</div>
            )}
        </div>
    );
};

export default CustomSelect;
