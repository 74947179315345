// AboutCitySection.js
import React, { useState } from "react";
import "./AboutCity.css";
import rightImg from "../../../assets/images/Membership/Rectangle.png";
import image1 from "../../../assets/images/AboutCity/image1.png";
import image2 from "../../../assets/images/AboutCity/image2.png";
import image3 from "../../../assets/images/AboutCity/image3.png";
import Section3 from "../../../assets/images/AboutCity/Groupedited.png";

// import hotel1 from "../../../assets/images/hotels/hotel1.jpg";
// import hotel2 from "../../../assets/images/hotels/hotel2.jpg";
// import hotel4 from "../../../assets/images/hotels/hotel4.jpg";
// import hotel5 from "../../../assets/images/hotels/hotel5.jpg";
// import hotel7 from "../../../assets/images/hotels/hotel7.jpg";
// import hotel8 from "../../../assets/images/hotels/hotel8.jpg";
// import hotel9 from "../../../assets/images/hotels/hotel9.jpg";
// import hotel10 from "../../../assets/images/hotels/hotel10.jpg";

import hotel3 from "../../../assets/images/hotels/hotel3.jpg";
import hotel6 from "../../../assets/images/hotels/hotel6.jpg";
import hotel11 from "../../../assets/images/hotels/hotel11.jpg";
import hotel12 from "../../../assets/images/hotels/hotel12.jpg";

import radissonBlu from "../../../assets/images/hotels/RadissonBlu.jpg";
import hellsten from "../../../assets/images/hotels/hellsten.png";
import rexhotel from "../../../assets/images/hotels/rexhotel.jpg";
import nomadgardet from "../../../assets/images/hotels/nomadgardet.jpg";



import AboutCityCardsModal from "./AboutCityCardsModel";

const AboutCityData = [
  "Stockholm, the capital of Sweden, encompasses 14 islands and more than 50 bridges on an extensive Baltic Sea archipelago. The cobblestone streets and ochre-colored buildings of Gamla Stan (the old town) are home to the 13th-century Storkyrkan Cathedral, the Kungliga Slottet Royal Palace and the Nobel Museum, which focuses on the Nobel Prize. Ferries and sightseeing boats shuttle passengers between the islands",
  "Stockholm, the capital of Sweden, encompasses 14 islands and more than 50 bridges on an extensive Baltic Sea archipelago. The cobblestone streets and ochre-colored buildings of Gamla Stan (the old town) are home to the 13th-century Storkyrkan Cathedral, the Kungliga Slottet Royal Palace and the Nobel Museum, which focuses on the Nobel Prize. Ferries and sightseeing boats shuttle passengers between the islands",
];

const travelInfoData = [
  {
    id: 1,
    image: image1,
    title: "Travel Info",
    subTitle: "To and from the airports",
    buttonText: "Read more",
    details: "1 More detailed information about travel to and from the airports.",
  },
  {
    id: 2,
    image: image3,
    title: "Travel Info",
    subTitle: "Ferries and cruise ships",
    buttonText: "Read more",
    details: "2 More detailed information about travel to and from the airports.",
  },
  {
    id: 3,
    image: image2,
    title: "Travel Info",
    subTitle: "To Stockholm by train",
    buttonText: "Read more",
    details: "3 More detailed information about travel to and from the airports.",
  },
];

const hotelInfoData = [
  {
    id: 1,
    image: hotel3,
    title: "Radisson Collection Strand Hotel",
    distance: "4 min walk",
    address: "Nybrokajen 9, 103 27 Stockholm, Sweden",
    price: "$236",
    link: "https://www.radissonhotels.com/en-us/booking/room-display?hotelCode=SESTOSTR&checkInDate=2024-10-13&checkOutDate=2024-10-16&adults%5B%5D=1&children%5B%5D=0&aoc%5B%5D=&searchType=pac&promotionCode=ST787681&voucher=",
    url:'https://www.radissonhotels.com',
    phone: "+46 8-50 664512",
    email:"event.stockholm@radissoncollection.com",
    discount:""
  },
  {
    id: 2,
    image: radissonBlu,
    title: "Radisson Blu Royal Viking",
    distance: "1 stop (8 min)",
    address: "Vasagatan 1, 111 20 Stockholm",
    price: "$213",
    link: "https://www.radissonhotels.com/en-us/booking/room-display?hotelCode=SESTOVIK&checkInDate=2024-10-14&checkOutDate=2024-10-15&adults%5B%5D=1&children%5B%5D=0&aoc%5B%5D=&searchType=pac&promotionCode=VI787687&voucher=",
    url:'https://www.radissonhotels.com',
    phone: "+46 72 5454084",
    email:"event.royalviking@radissonblu.com",
    discount:""
  },
  {
    id: 3,
    image: hotel6,
    title: "Scandic Anglais",
    distance: "11 min walk",
    address: "Humlegårdsgatan 23, 102 44 Stockholm, Sweden",
    price: "$141",
    link: "http://www.scandichotels.com/anglais",
    url:'http://www.scandichotels.com/anglais',
    phone: "+46 851 734000",
    email:"anglais@scandichotels.com",
    discount:"10% discount on the FLEX rate."
  },
  {
    id: 4,
    image: hellsten,
    title: "Hotel Hellsten",
    distance: "3 stops (13 min)",
    address: "Luntmakargatan 73, 113 51 Stockholm, Sweden",
    price: "$146",
    link: "https://hellsten.se",
    url:'https://hellsten.se',
    phone: "+46 086 618600",
    email:"hotel@hellsten.se",
    discount:"10% discount."
  },
  {
    id: 5,
    image: rexhotel,
    title: "Rex Hotel",
    distance: "3 stops (13 min)",
    address: "Luntmakargatan, 117 27 Stockholm, Sweden",
    price: "$116",
    link: "https://rexhotel.se",
    url: "https://rexhotel.se",
    phone: "+46 081 60040",
    email:"reception@rexhotel.se",
    discount:"10% discount."
  },
  {
    id: 6,
    image: hotel11,
    title: "Lilla Brunn",
    distance: "6 stops (16 min)",
    address: "Surbrunnsgatan 44, 111 30 Stockholm, Sweden",
    price: "$45",
    link: "https://www.lillabrunn.se",
    url: "https://www.lillabrunn.se",
    phone: "+46 822 9230",
    email: 'info@lillabrunn.se',
    discount: '10% discount.'
  },
  {
    id: 7,
    image: hotel12,
    title: "Nomad Cave",
    distance: "2 stops (14 min)",
    address: "Hantverkargatan 32, 112 21 Stockholm, Sweden",
    price: "$40",
    link: "http://www.nomadcave.se",
    url:'http://www.nomadcave.se',
    phone: "+46 855 926519",
    email: 'info@nomadcave.se',
    discount: '20% discount on all the rooms and beds.'
  },
  {
    id: 8,
    image: nomadgardet,
    title: "Nomad Gardet Hostel",
    distance: "2 stops (22 min)",
    address: "Sehlstedtsgatan 2, 115 28 Stockholm, Sweden",
    price: "$40",
    link: "https://nomadgardet.se",
    url:'https://nomadgardet.se',
    phone: "+46 105 558525",
    email: 'info@nomadcave.se',
    discount: '20% discount.'
  }
];

const panelsList = [
  "Hear from the representatives of the European and Nordic grant programs about their priorities and criteria for successful applications in 2025.",
  "SDG analysts, investors, and government officials will discuss the market and growth/change priorities in verticals such as climate, ocean, food, Health, Social Development, IT Deep Tech, etc."
];
const workshopList = [
  "Present your organizations/companies, visions and value propositions, projects and products and everything that you are doing to make a great impact.",
  "Participate in facilitated workshops to hear from other impact-driven companies and individuals, and build new concepts and partnerships out of the synergies.",
  "Leave the event with at least 10 concrete project ideas and consortiums for 2025 grant applications"
];
const socialActivitiesList = [
  "Participate at the Gala Dinner on the 14th and meet with some of the most influential people across the Nordics and Europe to realize your visions through funding and connections.",
  "Use our Ai platform to access the profile of every individual and company in the room and the ENFA network globally.",
  "Access the profile of the participants before the event and book meetings with them, all prepared for you at the amazing venue of our event, Berns.",
  "Participate in social activities outside of the event´s program - A guided tour around Stockholm and dinner on the 15th."
];
const AboutCitySection = () => {
  const [selectedData, setSelectedData] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [id, setTid] = useState(0);

  const openPopup = (data) => {
    setSelectedData(data);
  };

  const closePopup = () => {
    setSelectedData(null);
  };

  const clickHandle = (e) => {
    if (e) {
      setToggle(!toggle);
      setTid(e)

    }
  }

  

  const HotelCard = ({ id, image, title, distance, address, price, link, phone,email,discount,url }) => (
    <div className="col-md-4 mb-3">
      <div className="aboutCityCard hotelCard">
        <div className="overflow-hidden image">
          <img loading='lazy' alt="" src={image} />
        </div>
        <div className="content hotel-info">
          <div className="travel-info">{title}</div>
          <span className="to-and-from"><strong>Distance:</strong> {distance}</span>
          <span className="to-address"><strong>Address:</strong> {address}</span>
          <span className="to-price"><strong>Price for 14 - 15th Oct From:</strong> {price}</span>
          {discount ? (
            <>
            <span className="to-phone"><strong>Discount:</strong> {discount}</span>
            </>
          ) : (
            <>
            </>
          )}
          <span className="to-link"><strong>Website:</strong> <a href={link}>{url}</a></span>
          <span className="to-phone"><strong>Contact:</strong> {phone}</span>
          <span className="to-phone"><strong>Email:</strong> {email}</span>
        </div>
      </div>
    </div>
  );
  const TravelCard = ({ id, image, title, subTitle, buttonText, details }) => (
    <div className="col-md-4 ">
      <div className="aboutCityCard">
        <div className="overflow-hidden image">
          <img loading='lazy' alt="" src={image} />
        </div>
        <div className="content">
          <div className="travel-info">{title}</div>
          <span className="to-and-from">{subTitle}</span>
          {/* <button onClick={()=>clickHandle(1)} className="read-more">
            {buttonText}
          </button> */}
          <button className="read-more cursor-pointer" data-bs-toggle="modal" data-bs-target="#exampleModalCenter" onClick={() => clickHandle(id)}>{buttonText}</button>

        </div>
      </div>
    </div>
  );

  return (
    <section className="AboutCity-section">
      <div className="container">
        <div className="h-100 container mx-auto">
          <div className="d-flex align-items-center justify-content-center flex-column h-100">
            {/* <h3 className="aboutCity">About the city</h3>
            <h2 className="text-center mx-auto AboutCitySectionHeading">Stockholm</h2> */}

            {/* <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-12 col-lg-12 col-xl-6"> */}
                  {/* {AboutCityData?.map((item, index) => (
                    <p key={index} className="aboutcityP">{item}</p>
                  ))} */}
                {/* </div>
                <div className="col-xs-12 col-sm-6 col-md-12 col-lg-12 col-xl-6" >
                  <img loading='lazy' src={rightImg} alt="Right Image" className="rightImgAboutCity"/>
                </div>
              </div>
            </div> */}

            <h2 className="text-center mx-auto trevelInfoSectionHeading">Hotel Info</h2>

            <div className="container py-4">
              <div className="row">
                {hotelInfoData.map((item, index) => (
                  <HotelCard
                    key={index}
                    id={item.id}
                    image={item.image}
                    title={item.title}
                    distance={item.distance}
                    address={item.address}
                    price={item.price}
                    link={item.link}
                    phone={item.phone}
                    email={item.email}
                    discount={item.discount}
                    url={item.url}
                  />
                ))}
              </div>
            </div>

            <h2 className="text-center mx-auto trevelInfoSectionHeading">Travel Info</h2>

            <div className="container py-5">
              <div className="row">
                {travelInfoData.map((item, index) => (
                  <TravelCard
                    key={index}
                    id={item.id}
                    image={item.image}
                    title={item.title}
                    subTitle={item.subTitle}
                    buttonText={item.buttonText}
                    details={item.details}
                  />
                ))}
              </div>
            </div>
            {/* //modal */}

          </div>
        </div>

      </div>

      <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close cursor-pointer" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="">
              <AboutCityCardsModal sendId={id} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutCitySection;
