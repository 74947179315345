import React, { useState } from 'react'
import "./Signup.css";
import LeftContent from '../../assets/images/LeftContent.png'
import passwordView from '../../assets/images/login/eye-open.svg';
import passwordHide from '../../assets/images/login/eye-close.svg';
import sideBg from '../../assets/images/login/LeftContent.svg';
import { Formik, Field, ErrorMessage } from "formik";
import axiosInstance from '../../API/axiosInstance';
import { apiendpoints } from "../../appConstants";
import { toast } from "react-toastify";
import Loader from "../Wrapper/Loader/Loader";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";



const Signup = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [isSuccesfullySubmitted, setisSuccesfullySubmitted] = useState(false);

  const [formData, setFormData] = useState({
    fullName: '',
    organisationEmail: '',
    password: '',
    confirmPassword: '',
  });
  const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/

  const validatePasswordFormat = (password) => {

    // Custom password format validation logic
    let tester = passRegex.test(password)

    setPasswordValid(tester)
    return tester


  };


  const signupState = {
    fullName: '',
    organisationEmail: '',
    password: '',
    confirmPassword: '',
  }
  const signupValidationSchema = Yup.object({
    fullName: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Name should only contain letters")
      .max(50, "Max length is 50 characters")
      .required("Full name is required"),

    organisationEmail: Yup.string()
      .max(50, "Max length is 50 characters")
      .email("Invalid email address")
      .required("Email address is required"),

    password: Yup.string()
      // .matches(passRegex,'A')
      .test('custom-validation', '', validatePasswordFormat)
      .max(20, "Password must be between 8 and 20 characters")
      .min(8, "Password must be between 8 and 20 characters.")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Confirm password does not match with the password')
      .required("Password is required"),
  });



  const handleChange = (e, setFieldValue) => {
    const inputValue = e.target.value;
    const filteredValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
    setFieldValue(e.target.name, filteredValue);
  };

  const handleValidation = (values) => {
    // Your validation handling logic goes here
    console.log('Validation triggered:', values);
  };




  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };


  const isPasswordValid = () => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    return regex.test(formData.password);
  };

  return (

    <div className="container-fluid h-100 SignupPage">
      <div className="row h-100">
        <div className=" col-lg-6 cust-col  text-white signupMainDiv"
        >
          {/* <img loading='lazy' src={sideBg}/> */}
        </div>
        <div className="col-lg-6 cust-col d-flex align-items-center pt-sm-5">
          <div className="container signupRightDiv">
            <div className="row justify-content-center">
              <div >
                <h1 className="hello-again">Hello !</h1>
                <p className="fw-400 mb-4 welcomeBack">Sign Up to get started</p>
                <Formik
                  initialValues={signupState}
                  validationSchema={signupValidationSchema}
                  validateOnChange
                  onValidate={handleValidation}
                  onSubmit={(values) => {
                    setisSuccesfullySubmitted(true)
                    axiosInstance.post(apiendpoints.registerForGrantsAi, values)
                      .then(response => {
                        if (response?.data?.status === 'SUCCESS') {
                          // toast.success(response?.data?.message)
                          navigate("/ENFA-AI/thank-you");
                          setisSuccesfullySubmitted(false)

                        }
                      })
                      .catch(error => {
                        setisSuccesfullySubmitted(false)
                        toast.error(error?.response?.data?.message)
                      });
                  }}
                >


                  {({ values, handleSubmit, isSubmitting, setFieldValue, touched }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="mb-2">
                        <label htmlFor="name" className="form-label signupLable">Full Name <span style={{ color: '#ff2525' }}>*</span></label>
                        <Field type="text" name='fullName' className="form-control" id="name" placeholder="Enter full name" onChange={(e) => handleChange(e, setFieldValue)} />
                        <ErrorMessage
                          name="fullName"
                          component="div"
                          className="passwordMustBeContainerErr"
                        />
                      </div>
                      <div className="mb-2">
                        <label htmlFor="email" className="form-label signupLable">Organization Email Address <span style={{ color: '#ff2525' }}>*</span></label>
                        <Field type="email" className="form-control" id="email" name='organisationEmail' placeholder="Enter email address" />
                        <ErrorMessage
                          name="organisationEmail"
                          component="div"
                          className="passwordMustBeContainerErr"
                        />
                      </div>
                      <div className="mb-2">
                        <label htmlFor="password" className="form-label signupLable">
                          Password <span style={{ color: '#ff2525' }}>*</span>
                        </label>
                        <div className="InputGroup">
                          <Field
                            type={showPassword ? 'text' : 'password'}
                            className="InputField form-control"
                            id="password"
                            name='password'
                            placeholder="Enter password"
                          />
                          {!showPassword ?
                            <img loading='lazy' className="PasswordViewImg" src={passwordView} alt="" onClick={togglePasswordVisibility} /> :
                            <img loading='lazy' className="PasswordViewImg" src={passwordHide} alt="" onClick={togglePasswordVisibility} />
                          }
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="passwordMustBeContainerErr"
                        />
                      </div>
                      <div className={`passwordMustBeContainer my-2 mb-4 ${!passwordValid && touched.password ? 'text-danger' : ''}`}>

                        Password must be at least
                        <span className={`capitalLetter mb-3 ${!passwordValid && touched.password ? 'text-danger' : null}`}> 8 characters</span> and must contain at least a <span className={`capitalLetter mb-3 ${!passwordValid && touched.password ? 'text-danger' : null}`}> Capital Letter</span>,
                        a <span className={`number mb-3 ${!passwordValid && touched.password ? 'text-danger' : null}`}> Number</span>, and a
                        <span className={`specialCharacter mb-3 ${!passwordValid && touched.password ? 'text-danger' : null}`}> Special Character</span>.

                      </div>

                      <div className="confirmpassword-container" style={{ marginBottom: '2.5rem' }}>
                        <label htmlFor="confirmpassword" className="form-label signupLable">
                          Confirm Password <span style={{ color: '#ff2525' }}>*</span>
                        </label>
                        <div className="InputGroup">
                          <Field
                            type={showConfirmPassword ? 'text' : 'password'}
                            className="InputField form-control"
                            id="confirmpassword"
                            name='confirmPassword'
                            placeholder="Enter confirm password"
                          />
                          {!showConfirmPassword ?
                            <img loading='lazy' className="PasswordViewImg" src={passwordView} alt="" onClick={toggleConfirmPasswordVisibility} /> :
                            <img loading='lazy' className="PasswordViewImg" src={passwordHide} alt="" onClick={toggleConfirmPasswordVisibility} />
                          }
                        </div>
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="passwordMustBeContainerErr"
                        />

                        {/* <div className="passwordMustBeContainer mb-2">
                      {isConfirmPasswordValid() ? null : (
                        <div style={{ color: '#ff2525' }}>Passwords do not match.</div>
                      )}
                    </div> */}
                      </div>


                      <div className='d-block mx-auto text-decoration-none text-center' style={{ marginBottom: '2.5rem' }}>
                        <button className="btn btn-primary SignupBtn" disabled={isSubmitting && isSuccesfullySubmitted} type='submit'>{isSubmitting && isSuccesfullySubmitted ? <Loader /> : 'Sign Up'}</button></div>
                      <div className="noAccountTextWrapper mt-2 mb-4">
                        <div className="noAccountText">
                          <div className="alreadyAct">Already have an account ?</div>
                          <div className="signup">{`Login`}</div>
                        </div>
                      </div>

                    </form>
                  )}

                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Signup