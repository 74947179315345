import { Carousel } from 'bootstrap'
import React, { useEffect } from 'react'
import './Slider.css'
import g1 from '../../../assets/images/Summit2023/gallery/g1.jpg'
import g2 from '../../../assets/images/Summit2023/gallery/g2.jpg'
import g3 from '../../../assets/images/Summit2023/gallery/g3.jpg'
import g4 from '../../../assets/images/Summit2023/gallery/g4.jpg'
import g5 from '../../../assets/images/Summit2023/gallery/g5.jpg'
import g6 from '../../../assets/images/Summit2023/gallery/g6.jpg'
import g7 from '../../../assets/images/Summit2023/gallery/g7.jpg'
import g8 from '../../../assets/images/Summit2023/gallery/g8.jpg'
import g9 from '../../../assets/images/Summit2023/gallery/g9.jpg'
import g10 from '../../../assets/images/Summit2023/gallery/g10.jpg'
import g11 from '../../../assets/images/Summit2023/gallery/g11.jpg'
import g12 from '../../../assets/images/Summit2023/gallery/g12.jpg'
import g13 from '../../../assets/images/Summit2023/gallery/g13.jpg'
import g14 from '../../../assets/images/Summit2023/gallery/g14.jpg'
import g15 from '../../../assets/images/Summit2023/gallery/g15.jpg'
import g16 from '../../../assets/images/Summit2023/gallery/g16.jpg'
import g17 from '../../../assets/images/Summit2023/gallery/g17.jpg'
import g18 from '../../../assets/images/Summit2023/gallery/g18.jpg'
import g19 from '../../../assets/images/Summit2023/gallery/g19.jpg'
import g20 from '../../../assets/images/Summit2023/gallery/g20.jpg'
import g21 from '../../../assets/images/Summit2023/gallery/g21.jpg'
import g22 from '../../../assets/images/Summit2023/gallery/g22.jpg'
import g23 from '../../../assets/images/Summit2023/gallery/g23.jpg'
import g24 from '../../../assets/images/Summit2023/gallery/g24.jpg'
import g25 from '../../../assets/images/Summit2023/gallery/g25.jpg'
import g26 from '../../../assets/images/Summit2023/gallery/g26.jpg'
import g27 from '../../../assets/images/Summit2023/gallery/g27.jpg'
import g28 from '../../../assets/images/Summit2023/gallery/g28.jpg'
import g29 from '../../../assets/images/Summit2023/gallery/g29.jpg'
import g30 from '../../../assets/images/Summit2023/gallery/g30.jpg'
import SummitMSilder from './SummitMSilder'


const galleryPhotos = [g1, g2, g3, g4, g5, g6, g7, g8, g9, g10, g11, g12, g13, g14, g15, g16, g17, g18, g19, g20, g21, g22, g23, g24, g25, g26, g27, g28, g29, g30];
const delay = 2500;
// Screen Size
const getTranslatePercentage = () => {
  switch (true) {
    case window.innerWidth >= 1700:
      return 17;
    case window.innerWidth >= 1500:
      return 19;
    case window.innerWidth >= 1400:
      return 22;
    case window.innerWidth >= 1500:
      return 19;
    case window.innerWidth >= 1200:
      return 24;
    case window.innerWidth >= 1100:
      return 27;
    case window.innerWidth >= 1000:
      return 30;
    case window.innerWidth >= 992:
      return 31;
    case window.innerWidth >= 940:
      return 35;
    case window.innerWidth >= 800:
      return 37;
    case window.innerWidth >= 740:
      return 43;
    default: break;
  }
};
const SummitSlider = () => {

  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === galleryPhotos.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <>
     <section id='deskTopView'>
        <div
          className="d-flex justify-content-center align-items-center h-100 flex-column summitbannerContent"
          style={{ marginTop: '20px' }}>
          <span className="d-block text-uppercase"
          >Gallery</span>
        </div>

        <div className="slideshow">
          <div
            className="slideshowSlider"
            style={{ transform: `translate3d(${-index * getTranslatePercentage()}%, 0, 0)` }}
          >
            {galleryPhotos.map((backgroundColor, index) => (
              <div
                className="slide"
                key={index}
              >
                <img loading='lazy' src={backgroundColor} />
              </div>
            ))}
          </div>

          <div className="slideshowDots">
            {galleryPhotos.map((_, idx) => (
              <div
                key={idx}
                className={`slideshowDot${index === idx ? " active" : ""}`}
                onClick={() => {
                  setIndex(idx);
                }}
              ></div>
            ))}
          </div>
        </div>
      </section>
      <section id='mobileView' >
       <SummitMSilder photoList = {galleryPhotos}/>
      </section>
    </>

  )
}

export default SummitSlider


