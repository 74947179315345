import React, { useEffect } from 'react'
import SummitBanner from './Banner/SummitBanner'
import SummitAttendees from './Attendees/SummitAttendees'
import RecapComponent from './RecapSummit/RecapComponent'
import SummitRegister from './SummitRg/SummitRegister'
import SummitSlider from './Slider/SummitSlider'
import SummitTestimonial from './Testimonial/SummitTestimonial'
import SummitSpeaker23 from './Speaker/SummitSpeaker23'


const Summit23 = () => {
  return (
    <div className='bgColor'>
    <SummitBanner/>
    <SummitAttendees/>
    <RecapComponent/>
    <SummitRegister/>
    <SummitTestimonial/>
    <SummitSpeaker23/>
    <SummitSlider/>
    </div>
  )
}

export default Summit23