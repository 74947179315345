import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
  };

const SummitMSilder = (props) => {
  return (
    
        <div className="container-fluid">
          <div
            className="d-flex justify-content-center align-items-center h-100 flex-column summitbannerContent"
            style={{ marginTop: '20px', marginBottom: '4%' }}>
            <span className="d-block text-uppercase"
            >Gallery</span>
          </div>
          <div style={{ position: "relative", textAlign: 'center', marginBottom: '4%', paddingBottom:'10%' }}>
            <Carousel responsive={responsive}>
              {props.photoList.map((gPhotos, index) =>
                <div className="container" key={index}>
                  <img loading='lazy' src={gPhotos} />
                </div>
              )
              }
            </Carousel>
          </div>
        </div>

  )
}

export default SummitMSilder