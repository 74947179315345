import React from 'react'
import './Summit24AboutSection.css'
const Summit24AboutSection = () => {
  return (

    <section className="summitAboutbannerSection">
      <div className="summitAboutbannerSectionBG">
        <div className="h-100 container">
          <div className='d-flex align-items-center justify-content-center flex-column h-100 gap-3'>
            <h3 style={{ color: " #fff" }}>ENFA Business Summit 2024</h3>
            <h2
              className="  text-center mx-auto bannerHeadingsec"
            >
              Build What Matters
            </h2>
            <div className="d-flex flex-column flex-lg-row d-md-flex d-lg-flex SummitAboutSecDates">
              <span>Date: 14 - 15th October</span>
              <span>Venue: Berns, Stockholm</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Summit24AboutSection