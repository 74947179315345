import React from 'react'
import mission_1 from './../../../assets/images/about/mission-img-1.jpg'
import mission_2 from './../../../assets/images/about/mission-img-2.jpg'
import mission_3 from './../../../assets/images/about/mission-img-3.jpg'
import mission_4 from './../../../assets/images/about/mission-img-4.jpg'
import mission_5 from './../../../assets/images/about/mission-img-5.jpg'
import mission_6 from './../../../assets/images/about/mission-img-6.jpg'
import mission_7 from './../../../assets/images/about/mission-img-7.jpg'
import mission_8 from './../../../assets/images/about/mission-img-8.jpg'
import mission_9 from './../../../assets/images/about/mission-img-9.jpg'
import mission_10 from './../../../assets/images/about/mission-img-10.jpg'
import mission_11 from './../../../assets/images/about/mission-img-11.jpg'
import mission_12 from './../../../assets/images/about/mission-img-12.jpg'
import './Mission.css'

export const MissionComponent = () => {
    return (
        <div style={{ backgroundColor: '#000000'}} className="pt-150 pb-150 pl-20 pr-20">
            <div className="container-fluid" >
                <div className="row">

                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1" style={{ textAlign: 'start' }}>
                    </div>


                    <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5" style={{ textAlign: 'start' }}>
                        <div className="aboutMissionCnt" >
                            <div className="text-uppercase textOur">Our</div>
                            <div className="text-uppercase textMisson">Mission</div>
                            <p>
                                ENFA is dedicated to empowering growth through strategic funding solutions. Our mission is to facilitate access to public funding for startups, SMEs, and associations across Europe and beyond. Through collaborative alliances and expertise in application writing, consortium building, project management, and innovative AI tools, we aim to foster sustainable development and success for our members and partners.
                            </p>

                        </div>
                    </div>

                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1" style={{ textAlign: 'start' }}>
                    </div>



                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" >
                        <div className="d-flex missionPhotoList"  >
                            <div className='row'>
                                <div className="col menu-container">
                                    <img loading='lazy' className='summitimg' src={mission_1} alt="" />
                                </div>
                                <div className="col menu-container">
                                    <img loading='lazy' className='summitimg' src={mission_2} alt="" />
                                </div>
                                <div className="col menu-container">
                                    <img loading='lazy' className='summitimg' src={mission_3} alt="" />
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col menu-container">
                                    <img loading='lazy' className='summitimg' src={mission_4} alt="" />
                                </div>
                                <div className="col menu-container">
                                    <img loading='lazy' className='summitimg' src={mission_5} alt="" />
                                </div>
                                <div className="col menu-container">
                                    <img loading='lazy' className='summitimg' src={mission_6} alt="" />
                                </div>
                            </div>


                            <div className='row'>
                                <div className="col menu-container">
                                    <img loading='lazy' className='summitimg' src={mission_7} alt="" />
                                </div>
                                <div className="col menu-container">
                                    <img loading='lazy' className='summitimg' src={mission_8} alt="" />
                                </div>
                                <div className="col menu-container">
                                    <img loading='lazy' className='summitimg' src={mission_9} alt="" />
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col menu-container">
                                    <img loading='lazy' className='summitimg' src={mission_10} alt="" />
                                </div>
                                <div className="col menu-container">
                                    <img loading='lazy' className='summitimg' src={mission_11} alt="" />
                                </div>
                                <div className="col menu-container">
                                    <img loading='lazy' className='summitimg' src={mission_12} alt="" />
                                </div>
                            </div>
                        </div>
                        
                      
                    </div>
                </div>
            </div>
        </div>
    )
}
