import React, { useState } from 'react'
import './ProgramDetails.css'
import calenderImg from '../../../assets/images/summit/calendar.png'
import locationImg from '../../../assets/images/summit/geolocation.png'

const programDetailsInfo = [{
  title: "Welcome breakfast",
  date: "8:30-9:30",
  location: "Terrassen",
  program: "Program :",
  programItems: ["Doors open", "Breakfast and Greetings"],
  topicTitle: "Topic : Intro to ENFA",
  topicItems: ["Welcome Remarks", "Recap of the summit last year"],
}, {
  title: "Strategic Focus",
  date: "9:45-10:15",
  location: "The ballroom",
  program: "Program :",
  programItems: ["Short presentations by program representatives of Erasmus+ , AMIF, Horizon, Cove, I3 Capacity Building, CERV, Interreg Europe"],
  topicTitle: " Topic : Priorities Addressed",
  topicItems: ["Representatives will each present their calls priorities during the next 2 years"],
},
{
  title: "Collaborative Synergy",
  date: "10:15-10:45",
  location: "The ballroom",
  program: "Program :",
  programItems: ["Panel among the program representatives of Erasmus+ , AMIF, Horizon, Cove, I3 Capacity Building, CERV, Interreg Europe + Q&A from the audience"],
  topicTitle: " Topic : Priorities Addressed",
  topicItems: ["The representatives will discuss the differences and overlaps among the calls and funding programs"],
},
{
  title: "AI Insights and Discussion",
  date: "11:00-11:45",
  location: "Terrassen",
  program: "Program :",
  programItems: ["Expert keynote on AI", "Launch of ENFA.ai tool."],
  topicTitle: "Topic : AI in Proposal Development ",
  topicItems: ["Role of Ai in Project Management and Proposal Development"],
},
{
  title: "Entrepreneurial Strategies",
  date: "12:00-12:30",
  location: "The ballroom",
  program: "Program :",
  programItems: ["Panel among renowned investors and representatives of leading innovation hubs such as accelerators and incubators in Sweden"],
  topicTitle: "Topic : Increasing Innovation in Proposals",
  topicItems: ["Streamlining better access to grants and partnerships for startups and applied research executives"],
},
{
  title: "Lunch",
  date: "12:30-13:30",
  location: "Terrassen",
  program: "Program :",
  programItems: ["Lunch and mingle"],
},
{
  title: "ENFA UKRAINE",
  date: "13:30-14:00",
  location: "The ballroom",
  program: "Program :",
  programItems: ["Keynote presentation by ENFA Ukraine Representative", "Panel among donor organizations in Sweden that support projects for Ukraine"],
  topicTitle: "Topic : Ukraine Projects and Grants",
  topicItems: ["Introduction to ENFA Ukraine Projects", "How to build consortiums for Ukraine"],
},
{
  title: "SPEED NETWORKING",
  date: "14:00-15:30",
  location: "",
  program: "Program :",
  programItems: ["ENFA App"],
  topicTitle: "Topic : Networking Opportunities",
  topicItems: ["Consortium building","Partnerships", "Service Exchange"],
},
{
  title: "ENFA Members Gathering",
  date: "15:30-16:30",
  location: "Ljusgarden",
  program: "Program :",
  programItems: ["ENFA members gathering"],
  topicTitle: "Topic : ENFA´s Strategies for 2025",
  topicItems: ["Recap of 2023-2024", "Intro to the strategy 2025", "Q&A and Discussions"],
}];
const programDetailsDay2Info = [{
  title: "Networking BREAKFAST",
  date: "8:30-10:00",
  location: "Terrassen",
  program: "Program :",
  programItems: ["Speed-dating"],
  topicTitle: "Topic: Pre-booked meetings by the audience through our app",
}, {
  title: "Morning Welcome",
  date: "10:00-10:05",
  location: "The ballroom",
  program: "Program :",
  programItems: ["Welcome Remarks"],
  topicTitle: "Topic: Welcome to the second day",
},
{
  title: "CONSORTIUM BUILDING WORKSHOP",
  date: "10:15-10:45",
  location: "The ballroom",
  program: "Program :",
  programItems: ["Roundtables prepared for different types of grants for stakeholders to join and build consortiums"],
  topicTitle: "Topic: Roundtables for programs Alliance For Innovation AMIF Horizon 1 Horizon 2",
  topicItems: ["Alliance For Innovation", "AMIF", "Horizon 1", "Horizon 2"],

},
{
  title: "Lunch",
  date: "12:00-13:00",
  location: "The ballroom",
  program: "Program :",
  programItems: ["Lunch and mingle"],
},
{
  title: "CONSORTIUM BUILDING WORKSHOP 2",
  date: "13:00-15:00",
  location: "The ballroom",
  program: "Program :",
  programItems: ["Round tables prepared for different types of grants for stakeholders to join and build consortium"],
  topicTitle: "Roundtables for programs: COVE I3 Capacity Building CERV Interreg Europe",
  topicItems: ["COVE", "I3 Capacity Building", "CERV", " Interreg Europe"],
}];

const ProgramDetailCard = ({ title, date, location, program, programItems, topicTitle, topicItems }) => (
  <div className="col-xs-12  col-sm-6  col-md-6 col-lg-6 col-xl-4 ">
    <div className="programDetailCard">
      <h3 className="text-uppercase title text-center">{title}</h3>
      <div className="d-flex flex-column flex-lg-row d-md-flex d-lg-flex justify-content-between programTime">
        <div className="d-flex align-items-center first flex-shrink-0">
          {date && (<><span className="d-block icon"> <img loading='lazy' src={calenderImg} alt="" /></span>
            <span className="d-block text"> {date}</span></>)}
        </div>
        <div className="d-flex align-items-center first flex-shrink-0">
          {location && (<><span className="d-block icon"> <img loading='lazy' src={locationImg} alt="" /></span><span className="d-block text">{location}</span></>)}
        </div>
      </div>
      <hr className="hrLine" />

      <div className="ProgramText">{program}</div>
      <ul className="ulProgram">
        {programItems?.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <div className=" ProgramText">{topicTitle}</div>
      <ul className="ulProgram">
        {topicItems?.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  </div>
);

const panelsList = [
  "Hear from the representatives of the European and Nordic grant programs about their priorities and criteria for successful applications in 2025.",
  "SDG analysts, investors, and government officials will discuss the market and growth/change priorities in verticals such as climate, ocean, food, Health, Social Development, IT Deep Tech, etc."
];
const workshopList = [
  "Present your organizations/companies, visions and value propositions, projects and products and everything that you are doing to make a great impact.",
  "Participate in facilitated workshops to hear from other impact-driven companies and individuals, and build new concepts and partnerships out of the synergies.",
  "Leave the event with at least 10 concrete project ideas and consortiums for 2025 grant applications"
];
const socialActivitiesList = [
  "Participate at the Gala Dinner on the 14th and meet with some of the most influential people across the Nordics and Europe to realize your visions through funding and connections.",
  "Use our Ai platform to access the profile of every individual and company in the room and the ENFA network globally.",
  "Access the profile of the participants before the event and book meetings with them, all prepared for you at the amazing venue of our event, Berns.",
  "Participate in social activities outside of the event´s program - A guided tour around Stockholm and dinner on the 15th."
];

const ProgramDetails = () => {
  const [selectedDay, setSelectedDay] = useState('Day 1');
  const handleDayClick = (day) => {
    setSelectedDay(day);
  };
  const selectedDayList = selectedDay === 'Day 1' ? programDetailsInfo : programDetailsDay2Info;
  return (
    <section className="ProgramDetailsSEction">

      <div className="container">
        <div className="h-100 container mx-auto ProgramBlock text-uppercase">
          <div className="d-flex align-items-center justify-content-center flex-column h-100">
            <h2 className="text-center mx-auto AboutCitySectionHeading">The Program Has 3 CORES</h2>
          </div>
        </div>
        <div className="container">
          <div className="row pb-5 g-3" style={{ marginBottom: '20px' }}>
            <div className="col-xs-12  col-sm-6  col-md-6 col-lg-6 col-xl-4 ">
              <div className="programDetailCard">
                <h3 className="text-uppercase title text-center mb-4">Panels</h3>
                <ul className="ulProgram core-program">
                  {panelsList?.map((item, index) => (
                      <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-xs-12  col-sm-6  col-md-6 col-lg-6 col-xl-4 ">
              <div className="programDetailCard">
                <h3 className="text-uppercase title text-center mb-4">Workshops</h3>
                <ul className="ulProgram core-program">
                  {workshopList?.map((item, index) => (
                      <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-xs-12  col-sm-6  col-md-6 col-lg-6 col-xl-4 ">
              <div className="programDetailCard">
                <h3 className="text-uppercase title text-center mb-4">Social Activities</h3>
                <ul className="ulProgram core-program">
                  {socialActivitiesList?.map((item, index) => (
                      <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="h-100 container mx-auto ProgramBlock text-uppercase">
          <div className="d-flex align-items-center justify-content-center flex-column h-100">
            <h2 className="text-center mx-auto AboutCitySectionHeading">Why YOU Should Attend</h2>
          </div>
        </div>
        <div className="container">
          <div className="row pb-2 g-3" style={{ marginBottom: '20px' }}>
            <div className="col-xs-12  col-sm-12  col-md-12 col-lg-12 col-xl-12">
                <ul className="ulProgram">
                 <li><strong>This is not a random gathering -</strong> we are hand-picking great impact-driven companies and individuals with world-class technologies and value propositions ready to scale.</li>
                 <li><strong>This is perhaps the most diverse gathering you will participate in 2024 -</strong> meet startups with amazing world-class technologies, unique NGOs from the Nordics with great access to Nordic grants, VCs and investment companies incentivized to help startups grow through grants, research institutions eager to commercialize their research results, municipalities needing innovative stakeholders and consultants to work with, and authorities eager to support the development of world-class projects and technologies. All of this spans industries such as Climate, Green Transition, Food-Tech, Health-Tech, Forest, Social Development, Immigration, Deep-Tech, IT, and more. </li>
                 <li><strong>This event expands your knowledge and access to grants more globally -</strong> from the Nordics, EU Commission, US, Africa, India, Ukraine, and more. You will meet potential partners from all these regions, enhancing your market growth and grant applications. </li>
                 <li><strong>This event will encourage and inspire you -</strong> to work more collaboratively and get closer to the top of the curve - where world-class innovation is happening. </li>
                 <li style={{ listStyle: "none", marginTop: "10px" }}><strong>Be bold. Be curious. Be there.</strong></li>
                </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="h-100 container mx-auto ProgramBlock text-uppercase pt-5">
          <div className="d-flex align-items-center justify-content-center flex-column h-100">
            <h2 className="text-uppercase text-center mx-auto bannerHeadingPD">
              CORE PROGRAM
            </h2>
            <p className="p text-uppercase">BERNS, STOCKHOLM </p>
            <span className="date-show">14 - 15th OCTOBER</span>
          </div>
        </div>
        <hr className="horizontalLine" />
        <div className="d-flex align-items-center justify-content-center flex-column pt-5">
          <div className="divaabutton1">
            <div className="readmore1">
              <button className={`link-button ${selectedDay === "Day 1" ? "selected" : ""}`} onClick={() => handleDayClick("Day 1")}>
                Day 1
              </button>
              <button className={`link-button ${selectedDay === "Day 2" ? "selected" : ""}`} onClick={() => handleDayClick("Day 2")} >
                Day 2
              </button>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row g-3" style={{ marginBottom: '20px' }}>
            {selectedDayList?.map((info, index) => (
              <ProgramDetailCard key={index} {...info} />
            ))}
          </div>
          {selectedDay === 'Day 1' && (<div className="">
            <div className="programDetailCard pb-5">
              <div className="d-flex  flex-column h-100">
                <h2 className=" text-center mx-auto bannerHeadingPD galaDinner align-items-center justify-content-center">
                  GALA DINNER
                </h2>
                <hr className="horizontalLine" />
                <div className='d-flex  align-items-center justify-content-between mx-auto GalaDateDiv'>
                  <div className="d-flex align-items-center first flex-shrink-0">
                    <><span className="d-block icon">
                      <img loading='lazy' src={calenderImg} alt="" />
                    </span>
                      <span className="d-block text">19:30-00:00</span></>
                  </div>
                  <div className="d-flex align-items-center first flex-shrink-0">
                    <><span className="d-block icon">
                      <img loading='lazy' src={locationImg} alt="" /></span>
                      <span className="d-block text">The ballroom</span></>
                  </div>
                </div>
              </div>
            </div>
          </div>)}

        </div>
      </div>
    </section>
  );
}

export default ProgramDetails