import React from 'react';
import './ThankyouPopup.css';

const ThankyouPopup = ({ closeThankYouPopup, successMessage }) => {
  const successfullyRegistered = successMessage && successMessage.includes("successfully registered");
  return (
    <div className="popup-overlay-Thankyou">
      <div className="popup-Thankyou">
        <div className="popup-Thankyou-content">
          <button className="close-popup cursor-pointer" onClick={closeThankYouPopup}>
          </button>
          {successMessage ? (
            <>
             {successfullyRegistered && <h2>Thank you</h2>} 
              <p>{successMessage}</p>
            </>
          ) : (
            <>
              <p>There was an error submitting the form. Please try again later.</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThankyouPopup;
