import React from 'react'
import BannerComponent from './Banner/BannerComponent'
import OverviewComponent from './Overview/OverviewComponent'

const GrantsOverview = () => {
    return (
        <div className='body'>
            <section className="aboutSection">
                <BannerComponent/>
                <OverviewComponent/>
            </section>
        </div>
    )
}
export default GrantsOverview